/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../../Navigation.css';

/**Transitions */

import app from '../../../firebase';
import FileUploader from '../FileUploader';
const db = app.firestore();


const VeterinarioDataForm = () => {

    const [clinica, setClinica] = useState('');
    const [doctor, setDoctor] = useState('');
    const [veterinarioPhone, setveterinarioPhone] = useState('');
    const [callTo, setCallto] = useState('');


    const savePet = () => {

        let pet_obj = localStorage.getItem('@pet_obj');
        pet_obj = JSON.parse(pet_obj)


        pet_obj["clinica"] = clinica;
        pet_obj["doctor"] = doctor;
        pet_obj["veterinarioPhone"] = veterinarioPhone;
        pet_obj["callTo"] = callTo;

        localStorage.setItem("@pet_obj", JSON.stringify(pet_obj));
        window.location.href = "/tag_medicine_form";


    }


    return (


        <>
            <div className='row' id="row-pc">

                <div className="col col-3"></div>

                <div className="col col-6">


                    <center>
                        <div className="badge-info"><span className='badge-title'>Veterinario</span></div>
                    </center>
                    <div className='d-flex flex-column justify-content-center mt-4 login-card'>

                        <div className='container'>
                            <div className='row row-cols-1'>
                                <div className='col-md-12'>
                                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Clínica' onChange={(e) => setClinica(e.target.value)} ></input>
                                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Doctor' onChange={(e) => setDoctor(e.target.value)}></input>
                                    <input type="number" class=" form-control mb-2 mt-2" placeholder='Número de teléfono' onChange={(e) => setveterinarioPhone(e.target.value)}></input>
                                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Hablar a' onChange={(e) => setCallto(e.target.value)}></input>
                                </div>
                            </div>

                        </div>

                        <div className='d-flex flex-row flex-column container-group mb-3'>
                            <button onClick={() => savePet()} type={"button"} style={{ width: "300px", background: "#28C2E3", color: "#fff" }} className="btn btn-main-rose mt-4">Siguiente</button>
                            <Link to={"/tag_medicine_form"} className="linkView mt-2">Omitir</Link>
                        </div>

                    </div>
                </div>

                <div className="col col-3"></div>
            </div>


            {/* MB */}

            <div className='row' id="row-mb">



                <center>
                    <div className="badge-info"><span className='badge-title'>Veterinario</span></div>
                </center>
                <div className='d-flex flex-column justify-content-center mt-4 login-card'>

                    <div className='container'>
                        <div className='row row-cols-1'>
                            <div className='col-md-12'>
                                <input type="text" class=" form-control mb-2 mt-2" placeholder='Clínica' onChange={(e) => setClinica(e.target.value)} ></input>
                                <input type="text" class=" form-control mb-2 mt-2" placeholder='Doctor' onChange={(e) => setDoctor(e.target.value)}></input>
                                <input type="number" class=" form-control mb-2 mt-2" placeholder='Número de teléfono' onChange={(e) => setveterinarioPhone(e.target.value)}></input>
                                <input type="text" class=" form-control mb-2 mt-2" placeholder='Hablar a' onChange={(e) => setCallto(e.target.value)}></input>
                            </div>
                        </div>

                    </div>

                    <div className='d-flex flex-row flex-column container-group mb-3'>
                        <button onClick={() => savePet()} type={"button"} style={{ width: "300px", background: "#28C2E3", color: "#fff" }} className="btn btn-main-rose mt-4">Siguiente</button>
                        <Link to={"/tag_medicine_form"} className="linkView mt-2">Omitir</Link>
                    </div>

                </div>
            </div>

        </>
    );



}


export default VeterinarioDataForm;