/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../../Navigation.css';

/**Transitions */

import app from '../../../firebase';
import FileUploader from '../FileUploader';
const db = app.firestore();


const ContactDataForm = () => {

    const [contactName, setContactName] = useState('');
    const [primaryNumber, setPrimaryNumber] = useState('');
    const [alternativeNumber, setAlternativeNumber] = useState('');
    const [location, setLocation] = useState('');
    const [email, setEmail] = useState('');

    const handleEmailInputChange = e => {
        const { value } = e.target;

        var mail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (e.target.value.match(mail) && e.target.value != "") {
            document.querySelector("#mailInput").className = 'form-control correctNumber';
            setEmail(value)
        } else {
            document.querySelector("#mailInput").className = 'form-control invalidNumber';
            setEmail("")
        }
    }

    const savePet = () => {

        if (contactName != "" && primaryNumber != "" && alternativeNumber != "" && location != "" && email != "") {
            let pet_obj = localStorage.getItem('@pet_obj');
            pet_obj = JSON.parse(pet_obj)


            pet_obj["contactName"] = contactName;
            pet_obj["primaryNumber"] = primaryNumber;
            pet_obj["alternativeNumber"] = alternativeNumber;
            pet_obj["location"] = location;
            pet_obj["email"] = email;

            localStorage.setItem("@pet_obj", JSON.stringify(pet_obj));
            window.location.href = "/tag_veterinario_form";

        } else {
            alert("Registre correctamente la información solicitada")
        }

    }


    return (


        <>
            {/* PC */}
            <div className='row' id='row-pc'>

                <div className="col col-3"></div>

                <div className="col col-6">


                    <center>
                        <div className="badge-info"><span className='badge-title'>Contacto</span></div>
                    </center>
                    <div className='d-flex flex-column justify-content-center mt-4 login-card'>

                        <div className='container'>
                            <div className='row row-cols-1'>
                                <div className='col-md-12'>
                                    <label htmlFor="mailInput" className='text-danger'>Email Requerido*</label>
                                    <input type="text" id='mailInput' oninput="this.value=this.value.toLowerCase()" class=" form-control mb-2 mt-2" placeholder='Email' onChange={handleEmailInputChange} ></input>
                                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Nombre' onChange={(e) => setContactName(e.target.value)}></input>
                                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Número primario' onChange={(e) => setPrimaryNumber(e.target.value)} ></input>
                                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Número alternativo' onChange={(e) => setAlternativeNumber(e.target.value)}></input>
                                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Dirección' onChange={(e) => setLocation(e.target.value)}></input>
                                </div>
                            </div>

                        </div>

                        <div className='d-flex flex-row flex-column container-group mb-3'>
                            <input type={"button"} onClick={() => savePet()} style={{ width: "300px", background: "#28C2E3", color: "#fff" }} className="btn btn-main-rose mt-4" value={"Siguiente"}></input>
                        </div>

                    </div>
                </div>

                <div className="col col-3"></div>
            </div>

            {/* MB */}
            <div className='row' id='row-mb'>




                <center>
                    <div className="badge-info"><span className='badge-title'>Contacto</span></div>
                </center>
                <div className='d-flex flex-column justify-content-center mt-4 login-card'>

                    <div className='container'>
                        <div className='row row-cols-1'>
                            <div className='col-md-12'>
                                <input type="text" id='mailInput' oninput="this.value=this.value.toLowerCase()" class=" form-control mb-2 mt-2" placeholder='Email' onChange={handleEmailInputChange} ></input>
                                <input type="text" class=" form-control mb-2 mt-2" placeholder='Nombre' onChange={(e) => setContactName(e.target.value)}></input>
                                <input type="text" class=" form-control mb-2 mt-2" placeholder='Número primario' onChange={(e) => setPrimaryNumber(e.target.value)} ></input>
                                <input type="text" class=" form-control mb-2 mt-2" placeholder='Número alternativo' onChange={(e) => setAlternativeNumber(e.target.value)}></input>
                                <input type="text" class=" form-control mb-2 mt-2" placeholder='Dirección' onChange={(e) => setLocation(e.target.value)}></input>
                            </div>
                        </div>

                    </div>

                    <div className='d-flex flex-row flex-column container-group mb-3'>
                        <input type={"button"} onClick={() => savePet()} style={{ width: "300px", background: "#28C2E3", color: "#fff" }} className="btn btn-main-rose mt-4" value={"Siguiente"}></input>
                    </div>

                </div>
            </div>


        </>
    );



}


export default ContactDataForm;