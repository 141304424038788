import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';

import FilterResults from 'react-filter-search';

// Firebase
import app from '../../firebase';
const db = app.firestore();

export default function AdminEmails() {



    const [emails, setEmails] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [parametro, setParametro] = useState('');

    const emailList = emails.map((email) => {
        const { delivery, to, message, id } = email
        const state = delivery && delivery.state ? delivery.state : ""
        const startTime = delivery && delivery.startTime ? new Date(delivery.startTime.seconds * 1000).toString() : ""
        const endTime = delivery && delivery.endTime ? new Date(delivery.endTime.seconds * 1000).toString() : ""
        const info = delivery && delivery.info ? delivery.info : {}
        const accepted = info && info.accepted ? info.accepted : []
        const response = info && info.response ? info.response : ""
        const text = message && message.text ? message.text : ""
        const subject = message && message.subject ? message.subject : ""
        const html = message && message.html ? message.html : ""
        const error = delivery && delivery.error ? delivery.error : "null"

        return {
            id,
            state,
            startTime,
            endTime,
            to,
            accepted,
            response,
            text,
            subject,
            html,
            error
        }
    })


    const handleSearchUserInputChange = e => {
        setParametro(e.target.value)
    }



    useEffect(() => {
        db.collection("mail").orderBy("delivery.startTime", "desc").onSnapshot((querySnapshot) => {
            const docs = []
            querySnapshot.forEach((doc) => {
                docs.push({ ...doc.data(), id: doc.id })
            })
            setEmails(docs)
            setIsLoading(false)
        })
    }, [])

    return (
        <div className="container">


            {
                isLoading == true ?

                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    :
                    <>
                        <div className="my-4">
                            <span className='text-dark fw-bold display-6'>Emails Sender Interface</span>
                        </div>
                        <div className="my-2">
                            <Link to="/ad/dashboard" className='text-decoration-underline text-dark fw-bold'>Regresar</Link>
                        </div>

                        <div>

                            <div className='my-4'>
                                <input placeholder="Buscar email" value={parametro} onChange={handleSearchUserInputChange} className="shadow form-control inputBuscarPedido"></input>
                            </div>


                            {
                                <FilterResults
                                    value={parametro}
                                    data={emailList}
                                    renderResults={results => (
                                        results.length == 0 ?
                                            <div>
                                                <div className='my-4'>
                                                    <span className='text-dark fw-bold display-6'>No hay resultados</span>
                                                </div>
                                            </div>
                                            :
                                            results.map((email) => (
                                                <div className='shadow my-4 p-4 border-left-blue' key={email.id}>
                                                    <div className='my-2'>Message id: {email.id}</div>
                                                    <div className='my-2'>To: {email.to}</div>
                                                    <div className='my-2'>State: {email.state}</div>
                                                    <div className='my-2'>Error: {email.error}</div>
                                                    <div className='my-2'>Start Time: {email.startTime}</div>
                                                    <div className='my-2'>End Time: {email.endTime}</div>
                                                    <div className='my-2'>Accepted: {email.accepted}</div>
                                                    <div className='my-2'>Response: {email.response}</div>
                                                    <div className="my-4">
                                                        <div className='my-2'>Subject: {email.subject}</div>
                                                        <div className='my-2 p-4'>Message: {email.text}</div>
                                                    </div>
                                                    <div className="my-4 p-4 bg-light-gray rounded shadow" dangerouslySetInnerHTML={{ __html: email.html }} />
                                                </div>
                                            ))
                                    )}
                                />
                            }
                        </div>
                    </>
            }
        </div>
    )
}
