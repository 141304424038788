import React, { useState, useEffect, useMemo } from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import app from '../firebase';
import WaFloatButton from './WAFloatButton';
const db = app.firestore();
export default function NuevoTagDemo() {

    const navigate = useNavigate()

    // States
    const [state, setState] = useState({})
    const [demoTagData, setDemoTagData] = useState({})
    const [loading, setLoading] = useState(false)
    const [showSecondForm, setShowSecondForm] = useState(false)

    // Functions
    const timenow = () => {
        var now = new Date(),
            ampm = 'am',
            h = now.getHours(),
            m = now.getMinutes(),
            s = now.getSeconds();
        if (h >= 12) {
            if (h > 12) h -= 12;
            ampm = 'pm';
        }

        if (m < 10) m = '0' + m;
        if (s < 10) s = '0' + s;
        return now.toLocaleDateString() + ' ' + h + ':' + m + ':' + s + ' ' + ampm;
    }

    const saveDemoTag = async () => {
        setLoading(true)
        let generatedUUID = uuidv4().toString().slice(0, 6)

        let idTag = generatedUUID

        state["tagId"] = idTag
        state["isActive"] = true
        state["created_at"] = timenow()

        await db.collection("tagsDemo").add(state).then((docRef) => {
            setDemoTagData({
                "tagId": idTag,
            })
            setLoading(false)
            navigate(`/tagDemo/confirmed/${idTag}`)
        })

    }

    const handleOnchageText = useMemo(() => {
        return (name, value) => {
            setState({ ...state, [name]: value });
        };
    }, [state]);


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return useMemo(() => {

        return (
            <div className="container minHeight-100 p-4 d-flex justify-content-center align-items-center flex-column bg-new-tagDemo">
                <div>
                    <WaFloatButton></WaFloatButton>
                    <div className="my-4 bg-light shadow w-50 mw-auto text-center p-2">
                        <span className='display-6 fw-bold text-dark'>¡Perfecto, comencemos!</span>
                    </div>

                    <div className="shadow w-50 mw-auto p-4 bg-light">

                        {
                            !showSecondForm ?
                                <>
                                    <div className="form-group my-2">
                                        <label>¿Cuál es tu nombre?</label>
                                        <input autocomplete="off" type="text" onChange={(e) => handleOnchageText("usuarioNombre", e.target.value)} name="usuarioNombre" className="form-control" />
                                    </div>
                                    <div className="form-group my-2">
                                        <label>Nombre de quien va a portar la Placa</label>
                                        <input autocomplete="off" type="text" name="nombrePortador" onChange={(e) => handleOnchageText("nombrePortador", e.target.value)} className="form-control" />
                                    </div>
                                    <div className="form-group my-2">
                                        <label>Dirección del portador</label>
                                        <input autocomplete="off" type="text" name="direccionPortador" onChange={(e) => handleOnchageText("direccionPortador", e.target.value)} className="form-control" />
                                    </div>
                                    <div className="form-group my-2">
                                        <label>Información vital (Opcional)</label>
                                        <input autocomplete="off" type="text" onChange={(e) => handleOnchageText("informacionVital", e.target.value)} name="informacionVital" placeholder='Ej. Persona con Autismo' className="form-control" />
                                    </div>
                                    <div className="form-group my-2">
                                        <button className='w-100 btn btn-full-rounded bg-orange text-light fw-bold' onClick={() => setShowSecondForm(true)}>
                                            Siguiente
                                        </button>
                                    </div>
                                </>
                                :
                                <div></div>



                        }
                        {
                            showSecondForm == true ?
                                <>

                                    <div className="form-group my-2">
                                        <button className='w-100 btn btn-full-rounded bg-orange text-light fw-bold' onClick={() => setShowSecondForm(false)}>
                                            Anterior
                                        </button>
                                    </div>

                                    <div className="form-group my-2">
                                        <label>Nombre del responsable</label>
                                        <input autocomplete="off" type="text" onChange={(e) => handleOnchageText("responsable", e.target.value)} name="responsable" placeholder='Puede ser: espos@, padre, madre, tutor, amigo o familiar' className="form-control" />
                                    </div>
                                    <div className="form-group my-2">
                                        <label>Tipo de sangre</label>
                                        <input autocomplete="off" type="text" onChange={(e) => handleOnchageText("tipoSangre", e.target.value)} name="tipoSangre" className="form-control" />
                                    </div>
                                    <div className="form-group my-2">
                                        <label>Enfermedades</label>
                                        <input autocomplete="off" type="text" onChange={(e) => handleOnchageText("enfermedades", e.target.value)} name="enfermedades" placeholder='Ej. Diabetes' className="form-control" />
                                    </div>
                                    <div className="form-group my-2">
                                        <label>Alergias</label>
                                        <input autocomplete="off" type="text" onChange={(e) => handleOnchageText("alergias", e.target.value)} name="alergias" className="form-control" />
                                    </div>
                                    <div className="form-group my-2">
                                        <label>Teléfono de contácto de emergencia</label>
                                        <input autocomplete="off" type="text" onChange={(e) => handleOnchageText("telefonoEmergencia", e.target.value)} name="telefonoEmergencia" className="form-control" />
                                    </div>
                                    <div className="form-group my-2">
                                        <label>¿A qué correo te deben llegar las alertas?</label>
                                        <input autocomplete="off" type="email" onChange={(e) => handleOnchageText("correoEmergencia", e.target.value)} name="correoEmergencia" className="form-control" />
                                    </div>

                                    <div className="my-4">
                                        <button disabled={loading ? true : false} className='w-100 btn btn-full-rounded bg-orange text-light fw-bold' onClick={() => saveDemoTag()}>
                                            Crear TagDemo
                                        </button>
                                    </div>

                                </>
                                :
                                <div></div>



                        }

                        <div className="my-4 text-center">
                            <span className="text-dark fs-12">Al crear tu <strong>TagDemo</strong> estás aceptando nuestro <a href="/privacidad" className='text-dark'>Aviso de Privacidad</a>, asimismo nuestros  <a href="/terminos">Términos y Condiciones</a></span>
                        </div>
                        <div className="my-4 text-center">
                            <Link to="/" className="text-dark fs-6 text-decoration-underline">Regresar</Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }, [])
}