import React, { useState } from 'react'
import { useEffect } from 'react'

export default function AvisoPrivacidad() {
    useEffect(() => {
        window.location.href = "https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/AvisoPrivacidadidMyQrAlert.pdf?alt=media&token=cf472795-afb5-4836-b383-68f535ade5a5"
    }, [])

    return (
        <div>
            Redirigiendo...
        </div>
    )
}
