/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link, useNavigate } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';


/**Transitions */

import app from '../../firebase';
const db = app.firestore();







const ClientProfile = () => {

    let navigate = useNavigate()

    let currentAuth = app.auth().currentUser

    const [userId, setUserId] = useState("");

    const [userData, setUserData] = useState({});
    const [newPassword, setNewPassword] = useState('');
    const [newPasswordConfirm, setNewPasswordConfirm] = useState('');
    const [newName, setNewName] = useState('');


    const changePassword = () => {
        let user = app.auth().currentUser;
        console.log(user.email)
        user.updatePassword(newPassword).then(() => {
            alert('Éxito, contraseña actualizada correctamente')
            sessionStorage.clear()
            window.location.href = "/login"
        }).catch((error) => {
            console.log(error.message)
        })
    }



    const updatePersonalData = async () => {
        await db.collection("usuarios").doc(userId).update({
            userName: newName
        }, {
            merge: true
        }).then(() => {
            alert("Información actualizada correctamente");
            window.location.reload();
        }).catch(() => {
            alert("No se pudo actualizar la información, intente nuevamente");
            window.location.reload();
        })
    }

    const deleteAccount = async () => {
        let userId = sessionStorage.getItem("userId");
        if (window.confirm("¿Realmente deseas eliminar tu cuenta?")) {
            let user = app.auth().currentUser;
            user.delete().then(() => {
                db.collection("usuarios").where("userId", "==", userId)
                    .get()
                    .then(function (querySnapshot) {
                        querySnapshot.forEach(function (doc) {
                            // doc.data() is never undefined for query doc snapshots
                            db.collection('pets').doc(doc.id).delete().then(() => {
                                alert("Cuenta eliminada, saliendo...")
                                window.location.href = "/login"

                            })
                        });
                    })

            })
        }
    }
    useEffect(() => {
        let userId = sessionStorage.getItem("userId");
        if (userId) {
            db.collection('usuarios').where("userId", "==", userId).get().then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    // doc.data() is never undefined for query doc snapshots
                    setUserData(doc.data())
                    setUserId(doc.id)
                });
            }).catch(function (error) {
                console.log("Error getting documents: ", error);
            });
        } else {
            navigate('/login')
        }
    }, [])


    return (


        <>
            <div className="containerDashboard">

                <div className='d-flex flex-row mx-4' id='row-pc'>
                    <Link to={"/cl/dashboard"} className="btn btnMyProfile goBackButton">Regresar</Link>
                </div>



                <div className='iconContainerSmall'></div>
                <div className="mt-4 p-4 login-card">


                    <div className='m-2'>
                        <h1 className='titleCardSection'>Datos del usuario</h1>
                    </div>

                    <div className='parrafAlert my-4'>
                        <div className='m-2'>
                            <span className='titleText'>Nombre: </span><span className='dataText'>{userData.userName}</span>
                        </div>
                        <div className='m-2'>
                            <span className='titleText'>Correo electrónico actual: </span><span className='dataText'>{userData.userEmail}</span>
                        </div>
                    </div>

                    <div className='d-flex flex-row-reverse'>
                        <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-target="#openChangeUserDataModal">Actualizar datos</button>
                    </div>
                    <div className='d-flex flex-row-reverse my-2'>
                        <button className="btn btn-danger" onClick={() => deleteAccount()}>Eliminar mi cuenta</button>
                    </div>
                </div>

                <div className='my-4'>
                    <Link to={"/cl/dashboard"} className="btn btn-secondary">Regresar</Link>
                </div>
            </div>

            {/* Modal actualizar información */}

            <div className='d-flex justify-content-center'>
                <div class="modal fade" id="openChangeUserDataModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modalCenter modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h2 class="titleText">Actualizar mi información</h2>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='mt-2'>
                                    <div className='form-group'>
                                        <label className='mt-3'>Nombre <span className='fieldRequired'>*</span></label>
                                        <input defaultValue={userData.userName} type="text" class="form-control mb-2 mt-2" onChange={(e) => setNewName(e.target.value)}></input>
                                    </div>
                                </div>
                                <div className='mt-2'>
                                    <div className='parrafAlert'>
                                        <label className='mt-3'>Actualización de contraseña</label>
                                        <div className='form-control'>
                                            <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-target="#openChangePasswordModal">Actualizar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary m-2" data-bs-dismiss="modal">Cancelar</button>
                                <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={() => updatePersonalData()}>Actualizar</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            {/* Fin Modal actualizar información */}
            {/* Modal contraseña */}

            <div className='d-flex justify-content-center'>
                <div class="modal fade" id="openChangePasswordModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modalCenter modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h2 class="titleText">Actualizar mi contraseña</h2>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='mt-2'>
                                    <div className='form-group'>
                                        <label className='mt-3'>Nueva contraseña</label>
                                        <input type="password" class="form-control mb-2 mt-2" onChange={(e) => setNewPassword("telOficina", e.target.value)}></input>
                                    </div>
                                    <div className='form-group'>
                                        <label className='mt-3'>Confirmar nueva contraseña <span className='fieldRequired'>*</span></label>
                                        <input type="password" class="form-control mb-2 mt-2" onChange={(e) => setNewPasswordConfirm("email", e.target.value)}></input>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary m-2" data-bs-dismiss="modal">Cancelar</button>
                                <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={() => changePassword()}>Actualizar</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            {/* Fin Modal contraseña */}
        </>
    );



}


export default ClientProfile;