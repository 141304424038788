/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link, useNavigate } from 'react-router-dom';
/**Css styles */
import '../Navigation.css';


/**Transitions */

import app from '../firebase';
const db = app.firestore();






const ResetPassword = () => {



    let navigate = useNavigate()
    const [emailUser, setEmailUser] = useState('')

    const handleReset = async () => {

        if (emailUser != "") {
            await app.auth().sendPasswordResetEmail(emailUser).then((response) => {
                alert("Correo de recuperación enviado");

                window.location.reload()
            }).catch((error) => {
                alert("Hubo un error interno, prueba nuevamente más tarde", error.message);
        })
    }
}

return (


    <>
        <div className='d-flex flex-column justify-content-center' id='containerLogin'>
            <div className="container-myQrCard">
                <a href='/' className='myQRAlertBlack'>My<span className='myQRAlert'>QR</span>Alert</a>
            </div>
            <div className='mt-4 login-card'>


                <div className='m-4'>
                    <h2 className="firstText">Recuperación de Contraseña</h2>
                    <div className="parrafAlert">
                        <label>Ingresa el correo electrónico con el que te registraste y te enviaremos las instrucciones para que puedas recuperar tu contraseña en MyQrAlert</label>
                    </div>
                    <div className='mt-2'>
                        <div className='form-group'>
                            <label className='mt-3'>Correo electrónico registrado</label>
                            <input type="email" class="form-control mb-2 mt-2" onChange={(e) => setEmailUser(e.target.value)}></input>
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-row flex-column container-group mb-4'>
                    <button onClick={() => handleReset()} className="btn btnBlack mb-2">Enviar</button>
                    <Link to={"/signup"} className="linkView mt-2">¿No tienes cuenta? Crea una aquí</Link>
                    <Link to={"/login"} className="linkView mt-2">Volver al inicio</Link>
                </div>
            </div>
        </div>

    </>
);



}


export default ResetPassword;