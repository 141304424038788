import React, { Component } from 'react';
import WebRoutes from './WebRoutes'


export default class App extends Component {
    render (){
      return(
        <WebRoutes />
      );
    }
}