/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../../Navigation.css';

/**Transitions */

import app from '../../../firebase';
import FileUploader from '../FileUploader';
const db = app.firestore();


const AllergyForm = () => {

    const [alergia, setAlergia] = useState('');
    const [reaccion, setReaccion] = useState('');
    const [ultimaActualizacion, setUltimaActualizacion] = useState('');
    const [remedio, setRemedio] = useState('');
    const [notasAlergias, setNotasAlergias] = useState('');


    const savePet = () => {

        let pet_obj = localStorage.getItem('@pet_obj');
        pet_obj = JSON.parse(pet_obj)


        pet_obj["alergia"] = alergia;
        pet_obj["reaccion"] = reaccion;
        pet_obj["notasAlergias"] = notasAlergias;
        pet_obj["ultimaActualizacion"] = ultimaActualizacion;
        pet_obj["remedio"] = remedio;


        localStorage.setItem("@pet_obj", JSON.stringify(pet_obj));
        window.location.href = "/tag_reward_form";


    }
    return (


        <>
            {/* PC */}
            <div className='row' id='row-pc'>

                <div className="col col-3"></div>

                <div className="col col-6">


                    <center>
                        <div className="badge-info"><span className='badge-title'>Alergias</span></div>
                    </center>
                    <div className='d-flex flex-column justify-content-center mt-4 login-card'>

                        <div className='container'>
                            <div className='row row-cols-1'>
                                <div className='col-md-12'>
                                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Alergia' onChange={(e) => setAlergia(e.target.value)}></input>
                                </div>
                            </div>

                            <div className='row row-cols-1'>
                                <div className='col-md-12'>
                                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Reacción' onChange={(e) => setReaccion(e.target.value)} ></input>
                                </div>
                            </div>

                            <div className='row row-cols-2'>
                                <div className='col-md-7'>
                                    <label>Última actualización</label>
                                    <input type="date" class=" form-control mb-2 mt-2" onChange={(e) => setUltimaActualizacion(e.target.value)} ></input>


                                </div>
                                <div className='col-md-5'>
                                    <label>Remedio</label>
                                    <input type="text" class=" form-control mb-2 mt-2" onChange={(e) => setRemedio(e.target.value)}></input>

                                </div>
                            </div>

                            <div className='row row-cols-1'>
                                <div className='col-md-12'>
                                    <textarea type="text" class=" form-control mb-2 mt-2" placeholder='Notas' onChange={(e) => setNotasAlergias(e.target.value)} ></textarea>
                                </div>
                            </div>

                        </div>

                        <div className='d-flex flex-row flex-column container-group mb-3'>
                            <button style={{ width: "300px", background: "#28C2E3", color: "#fff" }} onClick={() => savePet()} className="btn btn-main-rose mt-4">Siguiente</button>
                            <Link to={"/tag_reward_form"} className="linkView mt-2">Omitir</Link>
                        </div>

                    </div>
                </div>

                <div className="col col-3"></div>
            </div>

            {/* MB */}
            <div className='row' id='row-mb'>



                <center>
                    <div className="badge-info"><span className='badge-title'>Alergias</span></div>
                </center>
                <div className='d-flex flex-column justify-content-center mt-4 login-card'>


                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Alergia' onChange={(e) => setAlergia(e.target.value)}></input>

                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Reacción' onChange={(e) => setReaccion(e.target.value)} ></input>

                    <label>Última actualización</label>
                    <input type="date" class=" form-control mb-2 mt-2" onChange={(e) => setUltimaActualizacion(e.target.value)} ></input>



                    <label>Remedio</label>
                    <input type="text" class=" form-control mb-2 mt-2" onChange={(e) => setRemedio(e.target.value)}></input>


                    <textarea type="text" class=" form-control mb-2 mt-2" placeholder='Notas' onChange={(e) => setNotasAlergias(e.target.value)} ></textarea>


                    <div className='d-flex flex-row flex-column container-group mb-3'>
                        <button style={{ width: "300px", background: "#28C2E3", color: "#fff" }} onClick={() => savePet()} className="btn btn-main-rose mt-4">Siguiente</button>
                        <Link to={"/tag_reward_form"} className="linkView mt-2">Omitir</Link>
                    </div>

                </div>
            </div>
        </>
    );



}


export default AllergyForm;