/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../../Navigation.css';

/**Transitions */

import app from '../../../firebase';
import FileUploader from '../FileUploader';
const db = app.firestore();


const MedicineForms = () => {

    const [medicacion, setMedicacion] = useState('');
    const [proposito, setProposito] = useState('');
    const [notas, setNotas] = useState('');
    const [frecuenciaDiaria, setFrecuenciaDiaria] = useState('');
    const [tiempoDosis, setTiempoDosis] = useState('');
    const [fechaInicio, setFechaInicio] = useState('');
    const [duracion, setDuracion] = useState('');
    const [dias, setDias] = useState('');


    const savePet = () => {

        let pet_obj = localStorage.getItem('@pet_obj');
        pet_obj = JSON.parse(pet_obj)


        pet_obj["medicacion"] = medicacion;
        pet_obj["proposito"] = proposito;
        pet_obj["notas"] = notas;
        pet_obj["frecuenciaDiaria"] = frecuenciaDiaria;
        pet_obj["tiempoDosis"] = tiempoDosis;
        pet_obj["fechaInicio"] = fechaInicio;
        pet_obj["duracion"] = duracion;
        pet_obj["dias"] = dias;

        localStorage.setItem("@pet_obj", JSON.stringify(pet_obj));
        window.location.href = "/tag_allergy_form";


    }



    return (


        <>
            {/* PC */}
            <div className='row' id='row-pc'>

                <div className="col col-3"></div>

                <div className="col col-6">


                    <center>
                        <div className="badge-info"><span className='badge-title'>Medicamentos</span></div>
                    </center>
                    <div className='d-flex flex-column justify-content-center mt-4 login-card'>

                        <div className='container'>
                            <div className='row row-cols-1'>
                                <div className='col-md-12'>
                                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Medicación' onChange={(e) => setMedicacion(e.target.value)} ></input>
                                </div>
                            </div>

                            <div className='row row-cols-2'>
                                <div className='col-md-7'>
                                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Propósito' onChange={(e) => setProposito(e.target.value)} ></input>


                                </div>
                                <div className='col-md-5'>
                                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Notas' onChange={(e) => setNotas(e.target.value)} ></input>

                                </div>
                            </div>

                            <div className='row row-cols-2'>
                                <div className='col-md-7'>
                                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Frecuencia diaria' onChange={(e) => setFrecuenciaDiaria(e.target.value)}></input>


                                </div>
                                <div className='col-md-5'>
                                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Tiempo y dosis' onChange={(e) => setTiempoDosis(e.target.value)} ></input>
                                </div>
                            </div>

                            <div className='row row-cols-2'>
                                <div className='col-md-7'>
                                    <label htmlFor="">Fecha inicio</label>
                                    <input type="date" class=" form-control mb-2 mt-2" onChange={(e) => setFechaInicio(e.target.value)} ></input>


                                </div>
                                <div className='col-md-5'>
                                    <label htmlFor="">Duración</label>
                                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Escriba la duración' onChange={(e) => setDuracion(e.target.value)} ></input>
                                </div>
                            </div>

                            <div className='row row-cols-1'>
                                <div className='col-md-12'>
                                    <input type="number" min={1} class=" form-control mb-2 mt-2" placeholder='Días' onChange={(e) => setDias(e.target.value)} ></input>
                                </div>
                            </div>


                        </div>

                        <div className='d-flex flex-row flex-column container-group mb-3'>
                            <button onClick={() => savePet()} style={{ width: "300px", background: "#28C2E3", color: "#fff" }} className="btn btn-main-rose mt-4">Siguiente</button>
                            <Link to={"/tag_allergy_form"} className="linkView mt-2">Omitir</Link>
                        </div>

                    </div>
                </div>

                <div className="col col-3"></div>
            </div>

            {/* MB */}
            <div className='row' id='row-mb'>



                <center>
                    <div className="badge-info"><span className='badge-title'>Medicamentos</span></div>
                </center>
                
                <div className='d-flex flex-column justify-content-center mt-4 login-card'>


                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Medicación' onChange={(e) => setMedicacion(e.target.value)} ></input>


                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Propósito' onChange={(e) => setProposito(e.target.value)} ></input>



                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Notas' onChange={(e) => setNotas(e.target.value)} ></input>



                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Frecuencia diaria' onChange={(e) => setFrecuenciaDiaria(e.target.value)}></input>



                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Tiempo y dosis' onChange={(e) => setTiempoDosis(e.target.value)} ></input>

                    <label htmlFor="">Fecha inicio</label>
                    <input type="date" class=" form-control mb-2 mt-2" onChange={(e) => setFechaInicio(e.target.value)} ></input>



                    <label htmlFor="">Duración</label>
                    <input type="text" class=" form-control mb-2 mt-2" placeholder='Escriba la duración' onChange={(e) => setDuracion(e.target.value)} ></input>

                    <input type="number" min={1} class=" form-control mb-2 mt-2" placeholder='Días' onChange={(e) => setDias(e.target.value)} ></input>



                    <div className='d-flex flex-row flex-column container-group mb-3'>
                        <button onClick={() => savePet()} style={{ width: "300px", background: "#28C2E3", color: "#fff" }} className="btn btn-main-rose mt-4">Siguiente</button>
                        <Link to={"/tag_allergy_form"} className="linkView mt-2">Omitir</Link>
                    </div>

                </div>
            </div>

        </>
    );



}


export default MedicineForms;