/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link, useNavigate } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';


import app from '../../firebase';
const db = app.firestore();


const NewTag = () => {

    // Navigation
    let navigate = useNavigate();
    
    // States
    const [tagId, setTagId] = useState('');



    const validateIsActiveTag = (querySnapshot) => {
        let isActive = ''
        querySnapshot.forEach(function (doc) {
            isActive = doc.data().isActive
            console.log(isActive)
        });

        return isActive
    }
    const validateTagId = async () => {

        await db.collection('tags').where("tagId", "==", tagId).get().then(function (querySnapshot) {

            let isActive = validateIsActiveTag(querySnapshot)

            switch (isActive) {
                case true:
                    alert("El tag ya fue activado");
                    break;
                case false:
                    querySnapshot.forEach(function (doc) {
                        // doc.data() is never undefined for query doc snapshots
                        localStorage.setItem("@tagIdFirebase", doc.id);
                        localStorage.setItem("@tagId", tagId);
                        navigate("/tag_general_form");
                    });
                    break;
                case "":
                    alert("No se reconoció el tag, verifique e ingreselo nuevamente")
                    break;
                default:
                    console.log(isActive)
                    alert("No se pudo procesar el tag")
                    break;
            }


        }).catch(function (error) {
            alert("Tag inválido" + error);
        });



    }




    return (


        <>
            <div className="containerDashboard">

                <div className='d-flex flex-column justify-content-center mt-4 login-card'>
                    <div className='m-4'>
                        <h2 className='secondText'>Nuevo Tag</h2>
                        <div className='mt-2'>
                            <div className='form-group'>
                                <label className='mt-3'>Ingresa el ID del Tag</label>
                                <input type="text" className="form-control mb-2 mt-2" onChange={(e) => setTagId(e.target.value)} placeholder='Ej. 1fe26035' ></input>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-row flex-column container-group mb-3'>
                        <input type={"button"} onClick={() => validateTagId()} className="btn btnBlack" value={"Activar tag"}></input>
                        <Link to={"/cl/dashboard"} className="linkView mt-2">Cancelar</Link>
                    </div>
                </div>
            </div>

        </>
    );



}


export default NewTag;