import React from 'react'

export default function IconInfo({ iconLink, iconImageLink, iconText }) {
    return (

        <div className='w-100 d-flex justify-content-center align-items-center flex-column'>
            <div onClick={()=>window.open(iconLink)} className="icon-info-circle" style={{ backgroundImage: `url(${(iconImageLink)})`, backgroundSize: "contain" }}></div>
            <div className='text-center my-2'>
                <span className='text-dark fw-bold fs-6'>{iconText}</span>
            </div>
        </div>
    )
}
