import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../index.css';
export default function VerifyTagLink() {

    const [mensajeWhatsApp, setMensajeWhatsApp] = useState('');

    return (


        <main>
            <div className="containerDashboard">
                {/* MyQrAlert */}

                <div id="row-pc">

                    <div className="d-flex flex-row justify-content-center">
                        <div className='iconContainer'></div>
                    </div>
                    <div className="my-4 d-flex justify-content-center">
                        <h1 className='headText'>Geolocalización de personas</h1>
                    </div>
                </div>

                <div id="row-mb">
                    <div className="d-flex flex-row justify-content-center">
                        <div className="container-myQrCard my-4">
                            <span className='myQRAlertBlack'>My<span className='myQRAlert'>QR</span>Alert</span>
                        </div>
                    </div>


                    <div className="my-4 d-flex justify-content-center">
                        <h1 className='headText'>Geolocalización de personas</h1>
                    </div>
                </div>
            </div>
        </main>

    )
}
