/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link, useNavigate } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';
import Tag from './Tag';


// icons
import loadingIcon from '../../assets/icons/013_loading_icon_png.gif';

import app from '../../firebase';
const db = app.firestore();



const ActiveTags = () => {




    let navigate = useNavigate();

    // States
    const [tags, setTags] = useState([]);
    const [isLoading, setIsloading] = useState(true);
    const [userId, setUserId] = useState('')



    useEffect(() => {

        let userIdAuth = sessionStorage.getItem('userId');

        if (!userIdAuth) {
            navigate('/login')
        }

        db.collection('persons').where("userId", "==", userIdAuth).onSnapshot((querySnapshot) => {

            const docs = [];
            querySnapshot.forEach(doc => {
                docs.push({ ...doc.data(), id: doc.id })
            });

            setTags(docs);
            setIsloading(false)
        })
    }, [])


    return (


        <>

            {
                isLoading == true ?
                    <div className='containerDashboard'>
                        <div class="spinner-border" style={{ color: "#F26716" }} role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>

                    :

                    <div className="containerDashboard">
                        <div className='container'>
                            <div className="row row-cols-2">
                                <div className="col">
                                    <h2 className='fs-1 fw-bold my-4'>Tags activos</h2>
                                    <Link to={"/cl/dashboard"} className="btn btnMyProfile my-4">Volver</Link>
                                </div>
                                <div className="col">
                                </div>
                            </div>
                        </div>

                        {tags == "" ?
                            <div>
                                <div className='iconNoActiveTags'></div>
                                <h5>No has activado ningun tag, <Link className='linkView' to={'/new_tag'}>registra uno aquí</Link> </h5>
                            </div>
                            :
                            <div className="container my-4">
                                <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2'>
                                    {
                                        tags.map((tag) => (
                                            <div className="col my-4">
                                                <Tag nombre={tag.nombre} imgUrl={tag.personPhotoImageLink} tagId={tag.tagId}></Tag>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        }
                    </div>
            }
        </>
    );


}


export default ActiveTags;