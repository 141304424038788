import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/storage";
import { v4 as uuidv4 } from 'uuid';


const FileUploader = () => {
  const [progress, setProgress] = useState(0);
  const [imageLoaded, setImageLoaded] = useState(false);
  const inputFileRef = useRef(null);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const fileExt = file.name.split(".").pop(); // Obtener la extensión del archivo
    const fileName = `${uuidv4()}.${fileExt}`; // Generar el nombre único usando UUID v4 y la extensión
    const storageRef = firebase.storage().ref();
    const fileRef = storageRef.child(fileName);
    const uploadTask = fileRef.put(file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const uploadProgress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(uploadProgress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          localStorage.setItem("@fileLink", downloadURL);
          setProgress(100); // Establecer el progreso en 100 cuando la carga se completa
          setImageLoaded(true); // Mostrar el mensaje de "Imagen cargada"
          resetInputFile();
        });
      }
    );
  };

  const resetInputFile = () => {
    if (inputFileRef.current) {
      inputFileRef.current.value = "";
    }
  };

  useEffect(() => {
    let timer;
    if (imageLoaded) {
      timer = setTimeout(() => {
        setImageLoaded(false); // Ocultar el mensaje de "Imagen cargada" después de 3 segundos
      }, 3500);
    }
    return () => clearTimeout(timer);
  }, [imageLoaded]);

  return (
    <div>
      <input type="file" onChange={handleFileUpload} ref={inputFileRef} />
      {progress === 100 ? (
        <div>
          {
            imageLoaded ?
              <div class="alert alert-info" role="alert">
                <strong>Cargada, ya puedes actualizar la foto</strong>
              </div>
              :
              null
          }
        </div>
      ) : (
        <div class="progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100">
          <div class="progress-bar" style={{ "width": `${progress}%` }}></div>
        </div>
      )}
    </div>
  );
};
export default FileUploader;
