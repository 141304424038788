import React, { useState, useEffect } from 'react'
import { useSearchParams, useNavigate, useParams, Link } from 'react-router-dom';

// Firebase
import app from '../../firebase';
const db = app.firestore();

export default function DemoTags() {

    const [demos, setDemos] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    // Functions
    const returnIsActiveTag = (date) => {
        let words = date.split("/")
        let nextDay = Number(words[0]) + 1
        let month = words[1]
        let year = words[2]

        const expireDate = `${nextDay}/${month}/${year}`

        //If results == true has expired
        return date == expireDate

    }

    const dateExpiring = (date) => {
        let words = date.split("/")
        let nextDay = Number(words[0]) + 1
        let month = words[1]
        let year = words[2]
        const expireDate = `${nextDay}/${month}/${year}`
        return expireDate
    }
    useEffect(() => {
        db.collection("tagsDemo").orderBy("created_at", "asc").onSnapshot((querySnapshot) => {
            const docs = []
            querySnapshot.forEach((doc) => {
                docs.push({ ...doc.data(), id: doc.id })
            })
            setDemos(docs)
            setIsLoading(false)
        })
    }, [])
    return (
        <div className="container">


            {
                isLoading == true ?

                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    :
                    <>
                        <div className="my-4">
                            <span className='text-dark fw-bold display-6'>Lista de DemoTags</span>
                        </div>
                        <div className="my-2">
                            <Link to="/ad/dashboard" className='text-decoration-underline text-dark fw-bold'>Regresar</Link>
                        </div>


                        {
                            demos.map((demo) => (
                                <div className="my-4">
                                    <div className="shadow p-2">
                                        <div className="m-2">
                                            <span className='fs-6 fw-bold'>Fecha de creación: {demo.created_at}</span>
                                        </div>
                                        <div className="m-2">
                                            <span className='fs-6 fw-bold'>De: {demo.usuarioNombre}</span>
                                        </div>
                                        {
                                            returnIsActiveTag(demo.created_at) == true ?
                                                <div className="m-2">
                                                    <span className='fs-6 fw-bold'>Ha expirado</span>
                                                </div>
                                                :
                                                <div className="m-2">
                                                    <span className='fs-6 fw-bold'>Expira: {dateExpiring(demo.created_at)}</span>
                                                </div>
                                        }
                                        <div className="m-2">
                                            <span className='fs-6 fw-bold'>Demo tag: {demo.tagId}</span>
                                        </div>
                                        <div className="m-2">
                                            <span className='fs-6 fw-bold'>Nombre del portador: {demo.nombrePortador}</span>
                                        </div>
                                        <div className="boder m-2">
                                            <span className='fs-6 fw-bold'>Correo del usuario: {demo.correoEmergencia}</span>
                                        </div>
                                        <div className="boder m-2">
                                            <span className='fs-6 fw-bold'>Teléfono del usuario: {demo.telefonoEmergencia}</span>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }



                    </>
            }


        </div>
    )
}
