/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link, useNavigate } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';

import FilterResults from 'react-filter-search';

// Importing Uuid
import { v4 as uuidv4 } from 'uuid';

import app from '../../firebase';
import * as firebaseAdmin from 'firebase/auth'

const db = app.firestore();


const DashboardAdmin = () => {
    // Navigation
    let navigate = useNavigate();

    // States
    const [tagsIds, setTagsIds] = useState([]);
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [userData, setUserData] = useState([])
    const [parametro, setParametro] = useState('');
    const [parametroUsuario, setParametroUsuario] = useState('');
    const [numeroTags, setNumeroTags] = useState(0)
    const [currentUser, setCurrentUser] = useState({})
    const [currentTags, setCurrentTags] = useState([])
    const [isLoadingCurrentTags, setIsLoadingCurrentTags] = useState(false)
    const [activeTags, setActiveTags] = useState([])

    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    today = dd + '/' + mm + '/' + yyyy;

    // Tag state
    const [tagState, setTagState] = useState({
        "created_at": "",
        "personId": "",
        "tagId": "",
        "isActive": false
    })

    // handlers

    const handleIndividualTagTextChange = (name, value) => {
        setTagState({ ...tagState, [name]: value })
    }


    const handleSearchInputChange = e => {
        setParametro(e.target.value)
    }

    const saveIndividualTag = async () => {
        tagState["created_at"] = today;

        const valid = checkTag(tagState.tagId);
        valid.then(async (id) => {
            if (id == "") {
                await db.collection("tags").add(tagState).then(() => {
                    alert("Éxito, tag registrado exitosamente");
                    window.location.reload();
                }).catch(() => {
                    alert("Error, el tag no se registró, intente nuevamente");
                    window.location.reload();
                })
            } else {
                alert("Se han encontrado registros de este tagId. No se pueden duplicar los tagId")
            }
        })


    }


    const checkTag = async (tagId) => {
        let dataTag = "";
        await db.collection("tags").where("tagId", "==", tagId).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                dataTag = doc.id;
            })
        })

        return dataTag;
    }

    const deleteTag = async (idTag) => {

        if (window.confirm("¿Realmente desea eliminar el tag?")) {
            await db.collection("tags").doc(idTag).delete().then(() => {

                const queryTagIdInPet = db.collection("persons").where("tagId", "==", idTag)

                queryTagIdInPet.get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        doc.ref.delete();
                    })
                })

                alert("Éxito, La información del tag ha sido eliminada, asimismo se eliminó la información de la persona correspondiente al tagId");
            }).catch(() => {
                alert("Error, no fue posible la eliminación del tag, ninguna información se alteró");
            })
        } else {
            console.log(false)
        }

    }


    // Add tags lote

    const generateUuid = () => {
        let generatedUUID = uuidv4().toString().slice(0, 6)
        return generatedUUID;
    }

    const saveTags = async () => {

        alert("Almacenando tags, por favor, espere");
        for (let i = 0; i < numeroTags; i++) {
            await db.collection("tags").add({
                "created_at": today,
                "personId": "",
                "tagId": generateUuid(),
                "isActive": false
            })
        }

        setNumeroTags(0);
        alert("Éxito, todos los tags fueron almacenados correctamente");

    }


    // Funtion to close session
    const logout = () => {
        sessionStorage.clear();
        navigate('/ad/login');
    }

    const deleteUser = async (id, uid) => {
        if (window.confirm("¿Eliminar usuario?")) {

            alert("Eliminando usuario, por favor espere");
            await db.collection("usuarios").doc(id).delete().then(() => {



                firebaseAdmin.deleteUser(uid).then(() => {
                    console.log("User deleted from firebase auth")
                })


                db.collection("tags").where("userId", "==", id).get().then((querySnapshot) => {
                    querySnapshot.forEach((doc) => {
                        doc.ref.delete();
                    })
                })
                alert("Se ha eliminado el usuario y su información almacenada en la base de datos, también han sido eliminados sus tags")
            })
        } else {
            return false;
        }
    }

    const handleSetCurrentUser = (user) => {
        setIsLoadingCurrentTags(true)
        setCurrentUser(user)

        db.collection("persons").where("userId", "==", user.userId).get().then((query) => {
            let docs = [];
            query.forEach(doc => {
                docs.push({ ...doc.data(), id: doc.id });
            });
            setCurrentTags(docs)
            setIsLoadingCurrentTags(false)
        })
    }

    const restartTag = (idTag, personIdTag) => {
        if (window.confirm('¿Desea reiniciar el tag?')) {
            db.collection('tags').doc(idTag).update({
                "isActive": false
            }).then(() => {
                db.collection('persons').where('tagId', "==", personIdTag).get().then((query) => {
                    query.forEach((doc) => {
                        doc.ref.delete().then(() => {
                            alert('Información de la persona eliminada, se ha reiniciado el tag correctamente')
                        })
                    })
                })
            })
        } else {
            console.log(null)
        }
    }

    useEffect(() => {


        let adminIdAuth = sessionStorage.getItem('adminId')
        if (!adminIdAuth) {
            navigate('/ad/login')
        }

        db.collection('usuarios').where("userId", "==", adminIdAuth).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                setUserData(doc.data());
            })
            setIsLoading(false);
        })

        db.collection('tags')
            .orderBy('created_at', 'asc')
            .onSnapshot((querySnapshot) => {

                const docs = [];
                querySnapshot.forEach(doc => {
                    docs.push({ ...doc.data(), id: doc.id })
                });
                const activeTagsCount = docs.filter(doc => doc.isActive === true).length;
                setActiveTags(activeTagsCount);
                setIsLoading(false);
                setTagsIds(docs)
            });

        db.collection('usuarios')
            .orderBy('userName', 'asc')
            .onSnapshot((querySnapshot) => {

                const docs = [];
                querySnapshot.forEach(doc => {
                    docs.push({ ...doc.data(), id: doc.id })
                });

                setIsLoading(false);
                setUsers(docs)
            });
    }, [])
    return (


        <>

            {
                isLoading != true ?

                    <>
                        <div className='containerDashboard'>

                            <div className="containerProfileLogout">
                                <div>
                                    <button className='btn btnLogout m-2' onClick={() => logout()}>Salir</button>
                                </div>
                            </div>

                            <div className="container">
                                <div className="row row-cols-2">

                                    <div className="col-12 col-md-6">
                                        <div className='mt-4 shadow-table'>
                                            <div className='container'>
                                                <div className="row row-cols-auto">
                                                    <div className="col">
                                                        <button className="btn m-2 text-decoration-underline" data-bs-toggle="modal" data-bs-target="#addIndividualTagModal">Tag individual</button>
                                                    </div>
                                                    <div className="col">
                                                        <Link to={"/ad/demos"} className="btn m-2 text-decoration-underline">Demos</Link>
                                                    </div>
                                                    <div className="col">
                                                        <Link to={"/ad/messages"} className="btn m-2 text-decoration-underline">Mensajes</Link>
                                                    </div>
                                                    <div className="col">
                                                        <button className="btn m-2 text-decoration-underline" data-bs-toggle="modal" data-bs-target="#addLoteTags">Lote</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='my-3 text-center bg-light-gray p-2 r-20'>
                                                <span>Total de usuarios: <strong>{users.length}</strong></span>
                                                <input placeholder="Buscar tagId" value={parametroUsuario} onChange={(text) => setParametroUsuario(text.target.value)} className="my-4 form-control shadow"></input>
                                            </div>

                                            <div className="h-300 table-responsive table-sm">

                                                <table className='h-300 table table-striped'>
                                                    <thead>
                                                        <tr>
                                                            <th className='fs-12'>Correo electrónico</th>
                                                            <th className='fs-12'>Nombre de usuario</th>
                                                            <th className='fs-12'>Tags</th>
                                                            <th className='fs-12'>Acciones</th>
                                                        </tr>
                                                    </thead>



                                                    {
                                                        !users.length == false ?
                                                            <FilterResults
                                                                value={parametroUsuario}
                                                                data={users}
                                                                renderResults={results => (

                                                                    <tbody className='p-1'>

                                                                        {
                                                                            results.map((user, index) => (
                                                                                <tr key={index}>
                                                                                    <td className='fs-12'>{user.userEmail}</td>
                                                                                    <td className='fs-12'>{user.userName}</td>
                                                                                    <td className='fs-12'>
                                                                                        <button className='btn btn-outline-dark fs-12' onClick={() => handleSetCurrentUser(user)} data-bs-toggle="modal" data-bs-target="#modalVerTagsAsociados">Ver</button>
                                                                                    </td>
                                                                                    <td>
                                                                                        <button class="btn btn-danger fs-12" onClick={() => deleteUser(user.id, user.userId)}>Eliminar</button>
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                                                        }


                                                                    </tbody>

                                                                )}
                                                            />
                                                            :
                                                            <div className='iconNoNotificacions'></div>
                                                    }

                                                </table>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <div className="shadow-table d-flex flex-column m-4">

                                            <div className="d-flex">
                                                <div className="m-2">
                                                    <span className='text-dark fw-bold fs-6'>Tags totales: <span className='text-light bg-primary rounded p-1'>{tagsIds.length}</span></span>
                                                </div>
                                                <div className="m-2">
                                                    <span className='text-dark fw-bold fs-6'>Tags Activos: <span className='text-light bg-success rounded p-1'>{activeTags}</span></span>
                                                </div>
                                            </div>

                                            <div className='my-3 text-center bg-light-gray p-2 r-20'>
                                                <input placeholder="Buscar tagId" value={parametro} onChange={handleSearchInputChange} className="my-4 form-control shadow"></input>
                                            </div>
                                            <div className="h-300 table-responsive">
                                                <table className="h-300 table table-striped">
                                                    <thead>
                                                        <tr>
                                                            <th className='fs-12'>Fecha de registro</th>
                                                            <th className='fs-12'>Tag Id</th>
                                                            <th className='fs-12'>Link</th>
                                                            <th className='fs-12'>Estado</th>
                                                            <th className='fs-12'>Reiniciar</th>
                                                            <th className='fs-12'>Eliminar</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            !users.length == false ?
                                                                <FilterResults
                                                                    value={parametro}
                                                                    data={tagsIds}
                                                                    renderResults={results => (


                                                                        results.map((tagId, index) => (
                                                                            <tr key={index}>
                                                                                <td className='fs-12'>{tagId.created_at}</td>
                                                                                <td className='fs-12'>{tagId.tagId}</td>
                                                                                <td className='fs-12'><a className='linkView' target="_blank" href={`/tag/${tagId.tagId}`}>Ver</a></td>
                                                                                <td className='fs-12'>
                                                                                    {tagId.isActive == true ?
                                                                                        <span className='badge bg-success rounded text-dark'>"ACTIVO"</span>
                                                                                        :

                                                                                        <span className='badge bg-secondary rounded text-light'>INACTIVO</span>
                                                                                    }
                                                                                </td>
                                                                                <td><button class="btn btn-warning fs-12" onClick={() => restartTag(tagId.id, tagId.tagId)}>Reiniciar</button></td>
                                                                                <td><button class="btn btn-danger fs-12" onClick={() => deleteTag(tagId.id)}>Eliminar</button></td>
                                                                            </tr>
                                                                        ))



                                                                    )}
                                                                />
                                                                :
                                                                <div className='iconNoNotificacions'></div>
                                                        }



                                                    </tbody>
                                                </table>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>


                            <p className='my-2 firmaMb' id='row-mb'>
                                Desarrollado por Desarrollalab.com
                            </p>

                        </div>
                    </>
                    :
                    <div className='containerDashboard'>
                        <div class="spinner-border text-dark" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>




            }


            {/* Modal agregar tag indididual */}

            <div className='d-flex justify-content-center'>
                <div class="modal fade" id="addIndividualTagModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modalCenter modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h2 class="titleText">Agregar tag individual <span className='fieldRequired'>* Requerido</span></h2>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='mt-2'>
                                    <div className='form-group'>
                                        <label className='mt-3'>Tag ID <span className='fieldRequired'>*</span></label>
                                        <input defaultValue={tagState.tagId} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleIndividualTagTextChange("tagId", e.target.value)}></input>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary m-2" data-bs-dismiss="modal" onClick={() => setTagState("tagId", "")}>Cancelar</button>
                                <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={() => saveIndividualTag()}>Registrar tag</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            {/* Fin Modal agregar tag indididual */}

            {/* Modal agregar tag indididual */}

            <div className='d-flex justify-content-center'>
                <div class="modal fade" id="addLoteTags" tabindex="-1" aria-hidden="true">
                    <div class="modal-dialog modalCenter modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h2 class="titleText">Agregar tags por lote <span className='fieldRequired'>* Requerido</span></h2>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='mt-2'>
                                    <div className='form-group'>
                                        <label className='mt-3'>¿Cuántos tags necesita crear? <span className='fieldRequired'>*</span></label>
                                        <input type="text" class="form-control mb-2 mt-2" onChange={(e) => setNumeroTags(e.target.value)}></input>
                                    </div>
                                    <div className='form-group'>
                                        <label className='parrafAlert mt-3'>Se crearán <strong>{numeroTags == "" ? 0 : numeroTags}</strong> tags</label>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn btn-secondary m-2" data-bs-dismiss="modal" onClick={() => setNumeroTags(0)}>Cancelar</button>
                                <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={() => saveTags()}>Registrar tags</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            {/* Fin Modal agregar tag indididual */}


            {/* Modal ver tags asociados */}
            <div className='d-flex justify-content-center'>
                <div class="modal fade" id="modalVerTagsAsociados" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modalCenter modal-dialog-scrollable">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h2 class="titleText">Tags asociados de: <span className='text-dark fw-bold'>{currentUser.userName}</span></h2>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div class="modal-body">
                                <div className='my-2 p-2 '>


                                    {
                                        isLoadingCurrentTags == true ?
                                            <div class="spinner-border text-dark" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div>
                                            :

                                            currentTags.length != 0 ?
                                                currentTags.map((tag) => (
                                                    <div className='bg-light-gray p-2 my-2'>
                                                        <a href={`/tag/${tag.tagId}`} target={"_blank"} className='text-decoration-underline text-dark fw-bold'>{tag.tagId}</a>
                                                    </div>
                                                ))
                                                :
                                                <div>No hay tags asociados</div>
                                    }
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button className="btn btn-dark" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={() => setCurrentTags([])}>Aceptar</button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            {/* Fin Modal ver tags asociados */}


        </>
    );



}


export default DashboardAdmin;