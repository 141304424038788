/**React imports */
import React, { useEffect, useState } from 'react';
/**Router */
import { Link, useNavigate } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';

import { v4 as uuidv4 } from 'uuid';


/**Transitions */

import app from '../../firebase';
const db = app.firestore();


const AddTags = () => {
    // Navigation router
    let navigate = useNavigate();

    const [tags, setTags] = useState('');
    const [tagsIds, setTagsIds] = useState([]);

    const generateUuid = () => {

        let generatedUUID = uuidv4().toString().slice(0, 6)

        tagsIds.push(generatedUUID)

        return (generatedUUID);
    }

    const createInputs = () => {

        console.log(tags)
        let inputs = document.getElementById('inputs');

        for (let i = 0; i < tags; i++) {


            let div = document.createElement('div');
            let input = document.createElement('input');

            let textButton = document.createTextNode("Generar")
            let buttonGenerate = document.createElement("button");
            buttonGenerate.className = "btn btn-warning m-2"
            buttonGenerate.append(textButton)
            // buttonGenerate.addEventListener("click",()=>generateUuid());

            input.placeholder = "Tag id"
            input.className = "form-control m-2"
            input.setAttribute("disabled", true);

            input.setAttribute("value", generateUuid())
            div.append(input)
            // div.append(buttonGenerate)

            div.setAttribute("id", "newsInputs");
            inputs.append(div)


        }



    }

    const saveTags = async () => {


        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();

        today = dd + '/' + mm + '/' + yyyy;

        alert("Almacenando tags, por favor, espere");
        for (let i = 0; i < tagsIds.length; i++) {
            await db.collection("tags").add({
                "crated_at": today,
                "tagId": tagsIds[i],
                "isActive":false
            })
        }

        alert("Tags guardados");
        window.location.href = "/ad/dashboard";

    }




    useEffect(() => {
        let adminIdAuth = sessionStorage.getItem('adminId')
        if (!adminIdAuth) {
            navigate('/ad/login')
        }
    }, [])
    return (


        <>
            <div className='row' id='row-pc'>

                <div className="col col-6 background-addtags"></div>
                <div className="col col-6">

                    <div className='d-flex flex-column justify-content-center mt-4 login-card'>

                        <div className='m-4'>
                            {tags <= -1 ? <h2>Crear {tags + 1} nuevos tags</h2> : <h2>Crear {tags} nuevos tags</h2>}


                            <div className='container my-4'>

                                <div class="row row-cols-2">
                                    <div class="col">
                                        <input type="text" className="form-control mb-2 mt-2" placeholder='Lote' onChange={(e) => setTags(e.target.value)} ></input>
                                    </div>
                                </div>


                                <div class="row row-cols-1" id='inputs'></div>



                            </div>
                        </div>

                        <div className='d-flex flex-row flex-column container-group mb-3'>
                            <button onClick={() => saveTags()} style={{ width: "300px", background: "#DA985F", color: "#fff" }} className="btn btn-main-rose mt-4">Crear tags</button>
                            <Link to={"/ad/dashboard"} className="linkView mt-2">Volver al inicio</Link>
                        </div>
                    </div>
                </div>
            </div>

            <div className='row m-4' id='row-mb'>



                <div className='login-card m-4'>

                    <div className='m-4'>
                        {tags <= -1 ? <h2>Crear {tags + 1} nuevos tags</h2> : <h2>Crear {tags} nuevos tags</h2>}


                        <div className='container my-4'>

                            <div class="form-group">
                                <input type="text" className="form-control mb-2 mt-2" placeholder='Lote' onChange={(e) => setTags(e.target.value)} ></input>
                            </div>


                            <div class="row row-cols-1" id='inputs'>
                                {createInputs()}
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-row flex-column container-group mb-3'>
                        <button onClick={() => saveTags()} style={{ width: "300px", background: "#DA985F", color: "#fff" }} className="btn btn-main-rose mt-4">Crear tags</button>
                        <Link to={"/ad/dashboard"} className="linkView mt-2">Volver al inicio</Link>
                    </div>
                </div>

            </div>
        </>
    );



}


export default AddTags;