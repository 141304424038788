import React, { useState } from 'react'
import SubscriptionCardYear from './SubscriptionCardYear'
import SubscriptionCardMonthly from './SubscriptionCardMonthly'
import SubscriptionCardSpei from './SubscriptionCardSpei';


export default function SubscriptionsContainer({ personId, nombrePersona }) {
    const [planSelection, setPlanSelection] = useState(null);
    const [showPayMethod, setShowPayMethod] = useState(false);
    const [payMethod, setPayMethod] = useState(null)
    const handlePlanSelection = (plan) => {
        setPlanSelection(plan);
        setShowPayMethod(true)
    };

    const handleCanPay = () => {
        console.log(payMethod)
        console.log("plan ", planSelection)
        switch (payMethod) {
            case 1://paypal
                if (planSelection == 1) {
                    return <SubscriptionCardYear personId={personId} />;
                } else {
                    return <SubscriptionCardMonthly personId={personId} />;
                }
            case 2://spei
                return <SubscriptionCardSpei nombrePersona={nombrePersona} plan={planSelection} tagId={personId}/>
            default:
                return null;
        }
    }

    return (
        <div>
            {!showPayMethod ? (
                <div className="my-4 container gap-4 container-cardsubs">
                    <div className="row row-cols-12">
                        <div className="col-12 col-md-6">
                            <div className="card-pay my-1" onClick={() => handlePlanSelection(1)}>
                                <div>
                                    <div className="w-100 d-flex justify-content-left">
                                        <h2 className='fs-2 fw-bold'>Plan anual</h2>
                                    </div>
                                    <div className="w-100 d-flex justify-content-left">
                                        <div>
                                            <h5 className='fs-5 fw-bold text-gray-400' style={{ textDecoration: 'line-through' }}>$650</h5>
                                        </div>
                                    </div>
                                    <div className="w-100 d-flex justify-content-left">
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <h5 className='fs-2 fw-bold'>$399<span className='text-light fs-xsm'> oferta única</span></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card-pay my-1" onClick={() => handlePlanSelection(2)}>
                                <div>
                                    <div className="w-100 d-flex justify-content-left">
                                        <h2 className='fs-2 fw-bold'>Plan Mensual</h2>
                                    </div>
                                    <div className="w-100 d-flex justify-content-left">
                                        <div>
                                            <h5 className='fs-5 fw-bold text-gray-400' style={{ textDecoration: 'line-through' }}>$120</h5>
                                        </div>
                                    </div>
                                    <div className="w-100 d-flex justify-content-left">
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <h5 className='fs-2 fw-bold'>$39<span className='text-light fs-xsm'> oferta única</span></h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                payMethod !== null ?
                    handleCanPay()
                    :
                    <div className='shadow rounded-2xl p-2 bg-light'>
                        <div className="">Ahora selecciona un método de pago</div>
                        <div className="my-4 bb-1 w-50-responsive c-pointer p-3" onClick={() => setPayMethod(1)}>
                            <img className='icon-pay' src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpaypal-svgrepo-com.svg?alt=media&token=d9270ebd-123c-4d53-ac67-7808c1e8e228" />
                            <img className='icon-pay' src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fvisa-svgrepo-com.svg?alt=media&token=f1f3e92a-7cdc-494a-b7f0-3557ea0cb1b8" />
                            <img className="icon-pay" src='https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fmastercard-full-svgrepo-com.svg?alt=media&token=6a620360-3cb6-4e6a-b430-10ae8ab33346' />
                            &nbsp; <span className='fw-bold' id='row-pc'>Paypal o Tarjetas C/D</span>
                            <div id="row-mb">
                                <span className='fw-bold'>Paypal o Tarjetas C/D</span>
                            </div>
                        </div>
                        <div className="my-4 bb-1 w-50-responsive c-pointer p-3" onClick={() => setPayMethod(2)}>
                            <img className="icon-pay" src='https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fspei-1.svg?alt=media&token=50e4fa08-8f9a-4229-a687-3b0e6bc41e37' />
                            &nbsp;<span className='fw-bold'>Transferencia bancaria</span></div>
                    </div>
            )}
        </div>
    );
}
