/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link, useNavigate } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';


// icons
import loadingIcon from '../../assets/icons/013_loading_icon_png.gif';

import app from '../../firebase';


// Firebase

import firebase from 'firebase/compat/app';

// Importing sweet alert
import Swal from 'sweetalert2';
const db = app.firestore();





const DashboardClient = () => {

    let navigate = useNavigate();

    const fieldValue = firebase.firestore.FieldValue;


    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: false
    })


    const [messages, setMessages] = useState([]);
    const [userData, setUserData] = useState("");

    const [isLoadingMessages, setIsLoadingMessages] = useState(true);
    const [showModalMessages, setShowModalMessages] = useState(false);
    const [messageSelected, setMessageSelected] = useState({});
    const [showModalSendMessage, setShowModalSendMessage] = useState(false);
    const [messageUserState, setMessageUser] = useState({});

    const [state, setState] = useState({});

    let userId = sessionStorage.getItem('userId')

    // states
    const [isLoading, setIsLoading] = useState(true);

    const closeSession = () => {
        localStorage.clear()
        sessionStorage.clear()
        navigate('/login')
    }

    const handleOpenViewMessages = (message) => {
        setShowModalMessages(true)
        setMessageSelected(message)
        db.collection("messages").doc(message.id).update({
            "hasResponse": false
        })
    }

    const handleCloseViewMessages = () => {
        setShowModalMessages(false)
    }


    const handleTextChange = (name, value) => {
        setState({
            ...state,
            [name]: value
        })
    }

    const handleChangeTextMessage = (name, value) => {
        setMessageUser({
            ...messageUserState,
            [name]: value
        })
    }


    const timenow = () => {
        var now = new Date(),
            ampm = 'am',
            h = now.getHours(),
            m = now.getMinutes(),
            s = now.getSeconds();
        if (h >= 12) {
            if (h > 12) h -= 12;
            ampm = 'pm';
        }

        if (m < 10) m = '0' + m;
        if (s < 10) s = '0' + s;
        return now.toLocaleDateString() + ' ' + h + ':' + m + ':' + s + ' ' + ampm;
    }


    const sendResponse = () => {
        console.log("state", state)
        state["sent_at"] = timenow()
        state["userType"] = "client"
        db.collection('messages').doc(messageSelected.id).update({ responses: fieldValue.arrayUnion(state) }, { merge: true }).then(() => {

            db.collection("messages").doc(messageSelected.id).update({
                "hasResponseAdmin": false,
                "hasResponseClient": true
            }).then(() => {
                Toast.fire({
                    icon: 'success',
                    title: 'Mensaje enviado correctamente'
                })
                setShowModalMessages(false)
            })



        }).catch((error) => {
            Toast.fire({
                icon: 'error',
                title: 'No se pudo enviar el mensaje, intente nuevamente'
            })
        });
    }


    // Message center for client

    const sendMessage = () => {

        messageUserState["sent_at"] = timenow()
        messageUserState["userType"] = "client"
        messageUserState["userId"] = userId
        messageUserState["user"] = `${userData.userName} (${userData.userEmail})`

        db.collection("messages").add(messageUserState).then((response) => {
            Toast.fire({
                icon: 'success',
                title: 'Mensaje enviado correctamente'
            })


            db.collection("mail")
                .add({
                    to: "info@myqralert.com",
                    message: {
                        subject: "HA RECIBIDO UN MENSAJE | CENTRO DE MENSAJES MYQRALERT",
                        text: "HA RECIBIDO UN MENSAJE | CENTRO DE MENSAJES IDPETS",
                        html: `
                                <p>Mensaje recibido de: ${userData.userEmail}</p>
                                <p>&nbsp;</p>
                                <p style="padding-left: 40px;">${messageUserState.message}</p>
                                <center>
                                <p><img src="https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/LOGO-PNG.ico?alt=media&token=0743be12-56ea-4341-957c-f0501675355f" alt="" width="100" height="100"></p>
                                </center>
                                `,
                    },
                }).then(() => console.log("::ALERT SENT SUCCESSFULLY::"));
            setShowModalSendMessage(false)
        }).catch((error) => {
            Toast.fire({
                icon: 'error',
                title: 'Error al enviar mensaje, intenta nuevamente'
            })
            window.location.reload()
        })
    }

    const handleShowModalMessage = () => {
        setShowModalSendMessage(true)
    }


    useEffect(() => {
        let userIdAuth = sessionStorage.getItem('userId');

        if (!userIdAuth) {
            navigate('/403')
        }


        db.collection('usuarios').where("userId", "==", userIdAuth).get().then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
                setUserData(doc.data());
            })
            setIsLoading(false)
        })


        // get messages using userId with userIdAuth onSnapshot

        db.collection('messages').where("userId", "==", userIdAuth).onSnapshot((querySnapshot) => {
            const docs = [];
            querySnapshot.forEach((doc) => {
                docs.push({ ...doc.data(), id: doc.id })
            })
            setMessages(docs)
            setIsLoadingMessages(false)
        })
    }, [])


    return (


        <>

            {
                isLoading != true ?

                    <>
                        <div className='containerDashboard'>


                            <div className='d-flex justify-content-center'>
                                <h2 className='userNameText'>Hola, {userData.userName}</h2>
                            </div>

                            <div className="containerProfileLogout ">
                                <div>
                                    <Link to={"/cl/profile"} className='btn btnMyProfile m-2'>Mi perfil</Link>
                                </div>
                                <div>
                                    <button className='btn btnLogout m-2' onClick={() => closeSession()}>Salir</button>
                                </div>
                            </div>
                            <div className="container my-4" id='row-pc'>
                                <div className="row row-cols-2">
                                    <div className="col-12 col-md-6">
                                        <div className='mt-4 shadow-table' style={{ height: "auto" }}>
                                            <h2>Mensajes</h2>

                                            <div className="d-flex flex-row-reverse">
                                                <div className="my-2">
                                                    <button className='btn btn-dark fs-12' onClick={() => handleShowModalMessage()}>Nuevo</button>
                                                </div>
                                            </div>


                                            {
                                                showModalSendMessage == true ?

                                                    <div className="fade-div container-fluid border my-4 shadow">
                                                        <div className="p-2">
                                                            <div className="form-group my-4">
                                                                <label>Escribe el mensaje</label>
                                                                <input onChange={(e) => handleChangeTextMessage("message", e.target.value)} type="text" placeholder='Mensaje' className='form-control' />
                                                            </div>

                                                            <div className="form-group my-4 d-flex flex-row-reverse">
                                                                <button className='btn btn-dark m-2 w-50' onClick={() => sendMessage()}>Enviar</button>
                                                                <button className='btn btn-secondary m-2 w-50' onClick={() => setShowModalSendMessage(false)}>Cancelar</button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    :
                                                    <div></div>
                                            }

                                            <div className="table-responsive">
                                                <table>
                                                    <tbody>
                                                        {

                                                            isLoadingMessages == true ?

                                                                <div className="d-flex justify-content-center align-items-center">
                                                                    <div class="spinner-border text-primary" role="status">
                                                                        <span class="visually-hidden">Loading...</span>
                                                                    </div>
                                                                </div>
                                                                :

                                                                messages.length === undefined ?

                                                                    <tr>
                                                                        <td>No hay mensajes</td>
                                                                    </tr> :
                                                                    messages.map((message, index) => (
                                                                        <tr className='row w-100' key={index}>
                                                                            <button onClick={() => handleOpenViewMessages(message)} className='btn btn-light w-100 messageButton my-3 d-flex justify-content-between'>
                                                                                <th scope="row">{message.message}</th>
                                                                                <td>{message.sent_at.split(" ")[0]}</td>

                                                                                {
                                                                                    message.hasResponseAdmin == true ?
                                                                                        <span class="badge rounded-pill bg-danger">+1</span>

                                                                                        :
                                                                                        ""
                                                                                }
                                                                            </button>
                                                                        </tr>
                                                                    ))
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 p-4">
                                        <div className='bg-light shadow'>
                                            <div className='d-flex flex-row justify-content-center'>
                                                <Link className='btn button-action-card-1' to={'/cl/all_tags'}>
                                                    <div className='icon-container-card-1'></div>
                                                    <span className='title-card-action'>Mis tags</span>
                                                </Link>
                                                <Link className='btn button-action-card-4' to={'/new_tag'}>
                                                    <div className='icon-container-card-4'></div>
                                                    <span className='title-card-action' style={{ color: "#000", fontWeight: "600" }}>Nuevo tag</span>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            {/* MB view */}



                            <div className="containerMb" id='row-mb'>

                                <div className="container-myQrCard myQRAlertTextMb">
                                    <span className='myQRAlertBlack'>My<span className='myQRAlert'>QR</span>Alert</span>
                                </div>

                                <div className='d-flex justify-content-center'>
                                    <h2 className='nameUserMb'>Hola, {userData.userName}</h2>
                                </div>



                                <div className='mt-4 shadow-table' style={{ height: "auto" }}>
                                    <h2>Mensajes</h2>


                                    <div className="d-flex flex-row-reverse">
                                        <div className="my-2">
                                            <button className='btn btn-dark fs-12' onClick={() => handleShowModalMessage()}>Nuevo</button>
                                        </div>
                                    </div>


                                    {
                                        showModalSendMessage == true ?

                                            <div className="fade-div container-fluid border my-4 shadow">
                                                <div className="p-2">
                                                    <div className="form-group my-4">
                                                        <label>Escribe el mensaje</label>
                                                        <input onChange={(e) => handleChangeTextMessage("message", e.target.value)} type="text" placeholder='Mensaje' className='form-control' />
                                                    </div>

                                                    <div className="form-group my-4 d-flex flex-row-reverse">
                                                        <button className='btn btn-dark m-2 w-50' onClick={() => sendMessage()}>Enviar</button>
                                                        <button className='btn btn-secondary m-2 w-50' onClick={() => setShowModalSendMessage(false)}>Cancelar</button>
                                                    </div>

                                                </div>
                                            </div>
                                            :
                                            <div></div>
                                    }

                                    <table className='table'>
                                        <tbody>
                                            {

                                                isLoadingMessages == true ?

                                                    <div className="d-flex justify-content-center align-items-center">
                                                        <div class="spinner-border text-primary" role="status">
                                                            <span class="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>
                                                    :

                                                    messages.length === undefined ?

                                                        <tr>
                                                            <td>No hay mensajes</td>
                                                        </tr> :
                                                        messages.map((message, index) => (
                                                            <tr className='trRow' key={index}>
                                                                <button onClick={() => handleOpenViewMessages(message)} className='btn btn-light w-100 messageButton my-3 d-flex justify-content-between'>
                                                                    <th scope="row">{message.message}</th>
                                                                    <div>
                                                                        <td>{message.sent_at.split(" ")[0]}</td>
                                                                    </div>

                                                                    {
                                                                        message.hasResponseAdmin == true ?
                                                                            <span class="badge rounded-pill bg-danger">+1</span>

                                                                            :
                                                                            ""
                                                                    }

                                                                </button>
                                                            </tr>
                                                        ))
                                            }
                                        </tbody>
                                    </table>
                                </div>


                                <div className="shadow-table">
                                    <div className='section-actions'>
                                        <h1 className='columTitle'>Acciones principales</h1>
                                        <div className='d-flex flex-column' id='sectionActionsContainer'>
                                            <Link className='btn button-action-card-1' to={'/cl/all_tags'}>
                                                <div className='icon-container-card-1'></div>
                                                <span className='title-card-action'>Mis tags</span>
                                            </Link>
                                            <Link className='btn button-action-card-4' to={'/new_tag'}>
                                                <div className='icon-container-card-4'></div>
                                                <span className='title-card-action' style={{ color: "#000", fontWeight: "600" }}>Nuevo tag</span>
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <p className='my-2 firmaMb' id='row-mb'>
                                Desarrollado por Desarrollalab - Soluciones Tecnológicas
                            </p>

                        </div>

                        {
                            showModalMessages == true ?


                                <div className="container-full-space fade-div">
                                    <div className="m-2 d-flex flex-row-reverse w-100" onClick={() => handleCloseViewMessages()}>
                                        <span className='fs-4 c-pointer text-center'>x</span>
                                    </div>

                                    <div className="my-4 w-100 h-75 d-flex flex-column-reverse">

                                        <div className="table-responsive">
                                            <table className="table table-striped table-hover">
                                                <tbody>
                                                    <div className="p-2">
                                                        <div className="my-2">
                                                        </div>
                                                        {
                                                            messageSelected.userType == "client" ?

                                                                <div className="response-client my-4 d-flex flex-row-reverse">
                                                                    <div className='p-1'>
                                                                        <p className='text-align-right'>{messageSelected.message}</p>
                                                                        <p className='fs-12 gray-light-text text-align-right'>{messageSelected.sent_at}</p>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="response-admin my-2 p-2">
                                                                    <p>{messageSelected.message}</p>
                                                                    <p className='fs-12 text-dark'>{messageSelected.sent_at}</p>
                                                                </div>
                                                        }


                                                        {
                                                            messageSelected.responses !== undefined ?

                                                                messageSelected.responses.map((response, index) => (

                                                                    response.userType == "client" ?
                                                                        <div className="response-client my-4 d-flex flex-row-reverse">
                                                                            <div className='p-1'>
                                                                                <p className='text-align-right'>{response.response}</p>
                                                                                <p className='fs-12 gray-light-text text-align-right'>{response.sent_at}</p>
                                                                            </div>
                                                                        </div>
                                                                        :
                                                                        <div className="response-admin my-4 p-2">
                                                                            <p>{response.response}</p>
                                                                            <p className='fs-12 text-dark'>{response.sent_at}</p>
                                                                        </div>

                                                                ))
                                                                : ""
                                                        }


                                                    </div>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>

                                    <div className="w-100">

                                        <div className="d-flex">
                                            <input type="text" placeholder='Mensaje' onChange={(e) => handleTextChange("response", e.target.value)} className="m-1 form-control" />
                                            <button onClick={() => sendResponse()} className="m-1 btn btn-full-rounded bg-warning">➤</button>
                                        </div>
                                    </div>
                                </div>

                                : null
                        }
                    </>
                    :
                    <div className='containerDashboard'>
                        <div class="spinner-border" style={{ color: "#F26716" }} role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>
            }


        </>
    );



}


export default DashboardClient;