/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';

import emailjs from 'emailjs-com'
import app from '../../firebase';
const db = app.firestore();


const Signup = () => {

    const [fullName, setFullName] = useState('');
    const [mailUser, setMailUser] = useState('');
    const [mailUserConfirm, setMailUserConfirm] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setPasswordConfirm] = useState('');


    const handleSingup = async () => {

        if (mailUser == mailUserConfirm) {
            if (password == passwordConfirm) {

                app.auth().createUserWithEmailAndPassword(mailUser, password).then(async (userCredential) => {
                    console.log(userCredential.user)

                    let user = {
                        "userId": userCredential.user.uid,
                        "userName": fullName,
                        "userEmail": mailUser,
                    }


                    await db.collection("usuarios").add(user).then(() => {
                        alert("Registrado correctamente");
                        db.collection("mail")
                            .add({
                                to: mailUser,
                                message: {
                                    subject: `${fullName} Te damos la bienvenida a MYQRALERT`,
                                    text: "Bienvenido a MYQRALERT",
                                    html: `
                                <p>Nos da mucho gusto tenerte aquí ${fullName}</p>
                                <p>&nbsp;</p>
                                <p style="padding-left: 40px;">Para comenzar a cuidar de tus seres queridos, <a href="https://myqralert.com/login" rel="noopener noreferrer" target="_blank">Ingresa aquí</a></p>
                                <p style="padding-left: 40px;">¿Ya adquiriste tu plaquita inteligente? Si aún no lo haces, <a href="https://idpetshop.com/" rel="noopener noreferrer" target="_blank">Comprala aquí</a></p>
                                <p style="padding-left: 40px;">&nbsp;</p>
                                <p style="padding-left: 40px;">Con mucho cariño, por parte del equipo de MYQRALERT</p>
                                <center>
                                <p><img src="https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/LOGO-PNG.ico?alt=media&amp;token=0743be12-56ea-4341-957c-f0501675355f" alt="" width="100" height="100  "></p>
                                </center>
                                `,
                                },
                            }).then(() => console.log("::ALERT SENT SUCCESSFULLY::"));
                        window.location.href = "/login"
                    })

                }).catch((error) => {
                    var errorCode = error.code;
                    var errorMessage = error.message;

                    console.log("-> error code: " + errorCode)
                    if (errorCode == "auth/weak-password") {
                        alert("La contraseña debe tener al menos 6 caracteres ");
                    }
                });
            } else {
                alert("Las contraseñas no coinciden")
            }
        } else {
            alert("Los correos no coinciden")
        }
    }


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (


        <>
            <div className='d-flex flex-column justify-content-center p-4' id='containerLogin'>
                <div className="container-myQrCard">
                    <a href='/' className='myQRAlertBlack'>My<span className='myQRAlert'>QR</span>Alert</a>
                </div>
                <div className='mt-4 signup-card'>
                    <div className='m-4'>
                        <h2 className="firstText">Regístrate</h2>
                        <div className='form-group'>
                            <label className='mt-3'>Nombre completo</label>
                            <input type="text" className="form-control mb-2 mt-2" onChange={(e) => setFullName(e.target.value)} placeholder='Ej. Esmeralda G' ></input>
                        </div>

                        <div className='form-group'>
                            <label className='mt-3'>Correo electrónico</label>
                            <input type="email" className="form-control mb-2 mt-2" onChange={(e) => setMailUser(e.target.value)} placeholder='mail@example.com'></input>
                        </div>
                        <div className='form-group'>
                            <label className='mt-3'>Confirmar correo</label>
                            <input type="email" className="form-control mb-2 mt-2" onChange={(e) => setMailUserConfirm(e.target.value)} placeholder='mail@example.com'></input>
                        </div>
                        <div className='form-group'>
                            <label className='mt-3'>Contraseña (Mínmo 6 caracteres)</label>
                            <input type="password" className="form-control mb-2 mt-2" onChange={(e) => setPassword(e.target.value)}></input>
                        </div>
                        <div className='form-group'>
                            <label className='mt-3'>Vuelve a escribir tu contraseña</label>
                            <input type="password" className="form-control mb-2 mt-2" onChange={(e) => setPasswordConfirm(e.target.value)}></input>
                        </div>


                    </div>

                    <div className='d-flex flex-row flex-column container-group mb-4'>
                        <button onClick={() => handleSingup()} className="btn btnBlack mb-2">Registrarte</button>
                        <Link to={"/reset_password"} className="linkView mt-2">¿Olvidaste tu contraseña?</Link>
                        <Link to={"/login"} className="linkView mt-2">Volver al inicio</Link>

                        <div>
                            <span style={{ fontSize: "10px" }}>Al registrarte, estás aceptando nuestros <a href="https://myqralert.com/terminos" target={"_blank"}>términos y condiciones</a></span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );



}


export default Signup;