/**React imports */
import React, { useEffect } from 'react';
/**Router */
import { Link, useNavigate } from 'react-router-dom';
/**Css styles */
import '../../../Navigation.css';


const ConfirmSavedPerson = () => {

    let navigate = useNavigate();

    useEffect(() => {
        setTimeout(() => {
            navigate('/cl/all_tags');
        }, 4000);
    }, [])

    return (
        <>
            <div className="containerDashboard">
                <div className="d-flex justify-content-center">
                    <div className="containerAlert">
                        <div className='iconSuccess'></div>
                        <h1 className='firstText my-4'>Tag activado</h1>
                        <p>Puedes configurar tu tag ingresando desde tu lista de tags</p>
                        <Link className="btn btnBlack my-4" to={'/cl/all_tags'}>Ver mis tags</Link>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ConfirmSavedPerson;