import React from 'react';
import { Routes, Route } from 'react-router-dom'



import DashboardClient from './components/client-components/DashboardClient';
import ClientProfile from './components/client-components/ClientProfile';
import Login from './components/Login';
import Signup from './components/client-components/Signup';
import DashboardAdmin from './components/adminComponents/DashboardAdmin';
import ActiveTags from './components/client-components/ActiveTags';
import PersonDetails from './components/client-components/PersonDetails';
import NewTag from './components/client-components/NewTag';
import GeneralDataForm from './components/client-components/FormCreateNewTag/GeneralDataForm';
import ContactDataForm from './components/client-components/FormCreateNewTag/ContactDataForm';
import VeterinarioDataForm from './components/client-components/FormCreateNewTag/VeterinarioDataForm';
import MedicineForms from './components/client-components/FormCreateNewTag/MedicineForms';
import AllergyForm from './components/client-components/FormCreateNewTag/AllergyForm';
import RewardForm from './components/client-components/FormCreateNewTag/RewardForm';
import AlertConfirmSavedPet from './components/client-components/FormCreateNewTag/AlertConfirmSavedPet';
import AddTags from './components/adminComponents/AddTags';
import PersonInfoGeneral from './components/client-components/PersonInfoGeneral';
import NotFound from './components/client-components/NotFound';
import HomePage from './components/HomePage';
import VerifyTagLink from './components/VerifyTagLink';
import ResetPassword from './components/ResetPassword';
import Demo from './components/Demo';
import AvisoPrivacidad from './components/AvisoPrivacidad';
import Terminos from './components/Terminos';
import ConfirmSavedPerson from './components/client-components/FormCreateNewTag/ConfirmSavedPerson';
import DemoTags from './components/adminComponents/DemoTags';
import NuevoTagDemo from './components/NuevoTagDemo';
import TagDemo from './components/client-components/TagDemo';
import CentroMensajes from './components/adminComponents/CentroMensajes';
import AdminEmails from './components/adminComponents/AdminEmails';
import Info from './components/Info';
import Page403 from './components/client-components/403/Page403';
import ConfirmTagDemo from './components/ConfirmTagDemo';
import TagDemoCreate from './components/TagDemoCreate';
/**
 * <Route exact path="/authenticationUserAdmin/webServiceLoginAdministration" element={adminDashboard} />
 */
const WebRoutes = () => {

    return (
        <Routes>
            <Route exact path="/" element={<HomePage />} />
            {/* Vistas cliente */}
            <Route exact path='/cl/dashboard' element={<DashboardClient />} />
            <Route exact path='/cl/profile' element={<ClientProfile />} />
            <Route exact path='/signup' element={<Signup />} />
            <Route exact path='/cl/all_tags' element={<ActiveTags />} />

            {/* Login general */}

            <Route path='/login' element={<Login />} />
            <Route path='/ad/login' element={<Login />} />

            {/* Vistas admin */}

            <Route path='/ad/dashboard' element={<DashboardAdmin />} />
            <Route path='/ad/demos' element={<DemoTags />} />
            <Route path='/ad/add_tags' element={<AddTags />} />
            <Route path='/ad/messages' element={<CentroMensajes />} />
            <Route path='/ad/emails' element={<AdminEmails />} />


            {/* Persons */}


            {/* Pets */}
            <Route path='/tag_saved' element={<ConfirmSavedPerson />} />


            <Route path='/cl/person_details/:personId' element={<PersonDetails />} />
            <Route path='/tag/:personId' element={<PersonInfoGeneral />} />
            <Route path='/tag' element={<VerifyTagLink />} />

            {/* Tags */}

            <Route path='/new_tag' element={<NewTag />} />
            <Route path='/tag_general_form' element={<GeneralDataForm />} />
            <Route path='/tag_contact_form' element={<ContactDataForm />} />
            <Route path='/tag_veterinario_form' element={<VeterinarioDataForm />} />
            <Route path='/tag_medicine_form' element={<MedicineForms />} />
            <Route path='/tag_allergy_form' element={<AllergyForm />} />
            <Route path='/tag_reward_form' element={<RewardForm />} />
            <Route path='/tag_saved_alert' element={<AlertConfirmSavedPet />} />
            <Route path='/reset_password' element={<ResetPassword />} />

            {/* Demo */}
            <Route path='/demo' element={<Demo />} />
            <Route path='/tagDemo' element={<TagDemoCreate />} />
            <Route path='/tagDemo/confirmed/:tagId' element={<ConfirmTagDemo />} />
            <Route path='/tagDemoDetails/:personId' element={<TagDemo />} />

            {/* Aviso privacidad */}
            <Route path='/privacidad' element={<AvisoPrivacidad />} />
            <Route path='/terminos' element={<Terminos />} />


            {/* Not found */}
            <Route path='/not-found' element={<NotFound />} />

            {/* Forbiden */}
            <Route path='/403' element={<Page403 />} />


            {/* Info*/}
            <Route path='/info' element={<Info />} />
        </Routes>
    );

}

export default WebRoutes;