import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import '../index.css'
import WaFloatButton from './WAFloatButton'

export default function Terminos() {

    useEffect(() => {
        window.location.href = "https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/T%C3%A9rminos%20y%20Condiciones%20MyQrAlert..pdf?alt=media&token=f146c9e2-cb32-4ade-b26c-e88f52afe2e7"
    }, [])

    return (
        <div>
            Redirigiendo...
        </div>
    )
}
