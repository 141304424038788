/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../../Navigation.css';

import emailjs from 'emailjs-com'

import app from '../../../firebase';
const db = app.firestore();


const RewardForm = () => {

    const [recompensa, setRecompensa] = useState('');
    const [notasRecompensa, setNotasRecompensa] = useState('');
    const [pet, setPet] = useState('');

    let tagId = localStorage.getItem('@tagId');

    const savePet = async () => {

        let userId = sessionStorage.getItem('userId')

        let pet_obj = localStorage.getItem('@pet_obj');
        pet_obj = JSON.parse(pet_obj)

        pet_obj["recompensa"] = recompensa;
        pet_obj["notasRecompensa"] = notasRecompensa;
        pet_obj["userId"] = userId;



        await db.collection('pets').add(pet_obj).then(async () => {
            updateTagIdIsActive()
        }).catch((err) => console.log(err))

        localStorage.setItem("@pet_obj", JSON.stringify(pet_obj));

    }

    const updateTagIdIsActive = async () => {

        await db.collection("tags").where("tagId", "==", tagId)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    // doc.data() is never undefined for query doc snapshots
                    db.collection('tags').doc(doc.id).update({
                        "isActive": true,
                    }).then(() => {
                        console.log('Completed..')
                        window.location.href = "/tag_saved_alert";
                    })
                });
            })
            .catch(function (error) {
                console.log("Error getting documents: ", error);
            });




    }

    const recoverData = () => {

        let pet_obj = localStorage.getItem('@pet_obj');
        pet_obj = JSON.parse(pet_obj);

        setPet(pet_obj);
    }

    useEffect(() => {
        recoverData();
    }, [])

    return (


        <>
            {/* PC */}
            <div className='row' id='row-pc'>

                <div className="col col-3"></div>

                <div className="col col-6">


                    <center>
                        <div className="badge-info"><span className='badge-title'>Recompenza ofrecida</span></div>
                    </center>
                    <div className='d-flex flex-column justify-content-center mt-4 login-card'>

                        <div className='container'>

                            <div className='row row-cols-1'>
                                <div className='col-md-12'>
                                    <p className="mb-2 mt-2">
                                        En caso de que  <strong>{pet.petName}</strong> se pierda, ¿Cuánto estarías dispuesto a recompensar a quien lo regrese a tu lado?
                                    </p>
                                </div>
                            </div>

                            <div className='row row-cols-1'>
                                <div className='col-md-12'>
                                    <input type="number" min={1} className=" form-control mb-2 mt-2" placeholder='$' onChange={(e) => setRecompensa(e.target.value)} ></input>
                                </div>
                            </div>

                            <div className='row row-cols-1'>
                                <div className='col-md-12'>
                                    <textarea class=" form-control mb-2 mt-2" placeholder='Notas de recompensa' onChange={(e) => setNotasRecompensa(e.target.value)}></textarea>
                                </div>
                            </div>

                        </div>

                        <div className='d-flex flex-row flex-column container-group mb-3'>
                            <button style={{ width: "300px", background: "#28C2E3", color: "#fff" }} onClick={() => savePet()} className="btn btn-main-rose mt-4">Listo</button>
                        </div>

                    </div>
                </div>

                <div className="col col-3"></div>
            </div>

            {/* MB */}

            <div className='row' id='row-mb'>
                <center>
                    <div className="badge-info"><span className='badge-title'>Recompenza ofrecida</span></div>
                </center>
                <div className='d-flex flex-column justify-content-center mt-4 login-card'>
                    <p className="mb-2 mt-2">
                        En caso de que  <strong>{pet.petName}</strong> se pierda, ¿Cuánto estarías dispuesto a recompensar a quien lo regrese a tu lado?
                    </p>
                    <input type="number" min={1} className=" form-control mb-2 mt-2" placeholder='$' onChange={(e) => setRecompensa(e.target.value)} ></input>
                    <textarea class=" form-control mb-2 mt-2" placeholder='Notas de recompensa' onChange={(e) => setNotasRecompensa(e.target.value)}></textarea>
                </div>
                <div className='d-flex flex-row flex-column container-group mb-3'>
                    <button style={{ width: "300px", background: "#28C2E3", color: "#fff" }} onClick={() => savePet()} className="btn btn-main-rose mt-4">Listo</button>
                </div>
            </div>
        </>
    );



}


export default RewardForm;