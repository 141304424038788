import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import '../index.css';
import icon from '../assets/icons/LOGO-PNG.png'
import WaFloatButton from './WAFloatButton';
export default function HomePage() {


    return (


        <main>
            <WaFloatButton></WaFloatButton>
            <div>
                <header id='row-pc' className="d-flex flex-wrap justify-content-center py-3  border-bottom">
                    <a href="/" className="d-flex align-items-center m-2 mb-3 mb-md-0 me-md-auto text-dark text-decoration-none">
                        <img src="https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/LOGO-PNG.ico?alt=media&token=0743be12-56ea-4341-957c-f0501675355f" height="50" alt=""></img>
                    </a>

                    <ul>
                        <Link to={'/login'} className='m-2 nav-item btn bg-dark text-light btn-full-rounded'>Iniciar sesión</Link>
                        <Link to={'/signup'} className='m-2 btn btn-full-rounded fw-bold nav-item bg-orange text-light'>Regístrate</Link>
                    </ul>
                </header>

                <header id='row-mb'>
                    <div className="nav-container d-flex w-100 flex-row justify-content-center">
                        <div className="iconContainer"></div>
                    </div>
                    <nav className="navbar navbar-light bg-light">
                        <div className="container-fluid justify-content-center my-4">
                            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleExternalContent" aria-controls="navbarToggleExternalContent" aria-expanded="false">
                                <span className="text-dark">Menú de navegación </span>
                                <span className="navbar-toggler-icon"></span>
                            </button>
                        </div>
                    </nav>
                    <div className="collapse" id="navbarToggleExternalContent">
                        <div className="bg-light p-4">
                            <div className="items">
                                <div className='my-2'>
                                    <Link to={'/login'} className='m-2 nav-item btn bg-dark text-light btn-full-rounded'>Iniciar sesión</Link>
                                </div>
                                <div className='my-2'>
                                    <Link to={'/signup'} className='m-2 btn btn-full-rounded fw-bold nav-item bg-orange text-light'>Regístrate</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
            </div>

            <div className="my-4 p-2 text-center">
                <div className="my-4">
                    <span className='display-5 text-dark fw-bold'><strong className='text-orange'>MYQRALERT</strong> | Alérta médica</span>
                </div>

                <div className="my-4">
                    <span className='fs-5 text-dark fw-bold'>Es más que un QR, es tranquilidad y seguridad, todo en una misma plataforma</span>
                </div>
            </div>

            <div className="container p-4">
                <div className="row row-cols-2">
                    <div className="col-12 col-md-6 my-4">
                        <div className=" p-2">
                            <div className="icon-emergency"></div>
                            <span className='text-dark fw-bold fs-3'>Plataforma de emergencia</span>
                            <p>Cuida de tus seres queridos, asegura el núnca perdes contacto con ellos si están en una situación de emergencia. Cuidamos a quienes cuidas.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 my-4">
                        <div className=" p-2">
                            <div className="icon-avatar"></div>
                            <span className='text-dark fw-bold fs-3'>Perfil digital</span>
                            <p>Información de contacto, carnet digital y datos personales, todo en un mismo lugar. Puedes configurarlo a la medida.</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 my-4">
                        <div className=" p-2">
                            <div className="icon-registro-medico"></div>
                            <span className='text-dark fw-bold fs-3'>Registro médico</span>
                            <p>Integramos un carnet digital que puedes configurar para guardar la información de la salud del portador de la <strong>Placa Inteligente</strong></p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 my-4">
                        <div className=" p-2">
                            <div className="icon-correos"></div>
                            <span className='text-dark fw-bold fs-3'>Correos ilimitados</span>
                            <p>La placa puede ser escaneada sin límites, sin suscripciones o cuotas por uso </p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 my-4">
                        <div className=" p-2">
                            <div className="icon-switch"></div>
                            <span className='text-dark fw-bold fs-3'>Tu tienes el control</span>
                            <p>Con nuestro sistema de bloques de información distribuida, tu decides que información es visible al público, con eso sólo se ve lo que tu quieres que se vea</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 my-4">
                        <div className=" p-2">
                            <div className="icon-coords"></div>
                            <span className='text-dark fw-bold fs-3'>Coordenadas de geolocalización</span>
                            <p>Una vez que se escanea la placa, te enviamos las coordenadas exactas del lugar donde se ha leido la <strong>Plaquita inteligente</strong></p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 my-4">
                        <div className=" p-2">
                            <div className="icon-chat"></div>
                            <span className='text-dark fw-bold fs-3'>Contacto en un solo click</span>
                            <p>Registra contactos de emergencia, pueden ser, madre, padre, tutor, amigos o familiares</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 my-4">
                        <div className=" p-2">
                            <div className="icon-qr-3d"></div>
                            <span className='text-dark fw-bold fs-3'>QR único</span>
                            <p>Todas nuestras placas son únicas para cada usuario, usamos algorítmos especializados para que tu placa sea segura y únicamente para tí</p>
                        </div>
                    </div>
                    <div className="col-12 col-md-6 my-4">
                        <div className=" p-2">
                            <div className="icon-restart"></div>
                            <span className='text-dark fw-bold fs-3'>Reinicio de tag</span>
                            <p>Cambia la información de tu placa sin necesidad de comprar una nueva, puedes reiniciar la información y reutilizar tu <strong>TagId</strong></p>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container d-flex justify-content-center my-6">
                <div className="bg-light w-50 mw-50-responsive p-4 shadow">
                    <div className="d-flex justify-content-center">
                        <div className="icon-family"></div>
                    </div>
                    <div className="my-2">
                        <p className='text-dark fs-4 fw-bold'>Adquiere tu plaquita inteligente, actívala, y cuida de tus seres queridos hoy y siempre</p>
                    </div>
                    <div className="my-4 d-flex justify-content-center">
                        <Link to={"/signup"} className='btn btnBlack mb-2 fw-bold fs-6'>Activa tu tag ➤</Link>
                    </div>
                </div>
            </div>
            <div className="container d-flex justify-content-center my-6">
                <div className="bg-dark w-50 mw-50-responsive p-2 shadow">
                    <div className="my-2 text-center">
                        <p className='text-light fs-4 fw-bold'>¿Quieres darte una idea de cómo funciona?</p>
                    </div>
                    <div className="my-4 d-flex justify-content-center">
                        <Link to={"/demo"} className='btn btnBlack mb-2 fw-bold fs-6'>Ve una demo ➤</Link>
                    </div>
                </div>
            </div>

            <section className="my-6 d-flex justify-content-center">
                <div className="shadow  p-3 text-center bg-light mw-50-responsive">

                    <h2 className='display-6 fw-bold my-4 text-dark'>O Crea un TagDemo</h2>
                    <p className='my-4 text-dark fs-5'>Simula un tag de Demo para darte una idea de cómo <strong className='text-orange'>MYQRALERT</strong> te puede dar una mano en una emergencia</p>

                    <Link to='/tagDemo' className='btn btnBlack text-light fw-bold'>Crear TagDemo</Link>

                </div>
            </section>


            <footer className="bg-dark">
                <div className="p-4">
                    <div className="row row-cols-3 rounded">
                        <div className="col-12 col-md-4 links-group">
                            <span className='fs-4 my-4 fw-bold text-orange'>Redes sociales</span>
                            <ul className='my-4'>
                                <li className='my-2'>
                                    <a className='text-decoration-none text-light' target={"_blank"} rel={"noreferrer noopener"} href="http://www.tiktok.com/@myqralert">Tiktok</a>
                                </li>
                                <li className='my-2'>
                                    <a className='text-decoration-none text-light' target={"_blank"} rel={"noreferrer noopener"} href="https://www.instagram.com/myqralert">Instagram</a>
                                </li>
                                <li className='my-2'>
                                    <a className='text-decoration-none text-light' target={"_blank"} rel={"noreferrer noopener"} href="https://www.facebook.com/myqralert">Facebook</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-4 links-group">
                            <span className='fs-4 my-4 fw-bold text-orange'>Recursos</span>
                            <ul className='my-4'>
                                <li className='my-2'>
                                    <a className='text-decoration-none text-light my-2' href="https://www.idpetshop.com/qr" target={"_blank"} rel={"noreferrer noopener"}>Tienda en línea ➤</a>
                                </li>
                                <li className='my-2'>
                                    <a className='text-decoration-none text-light my-2' href="/login">Acceder a mi cuenta ➤</a>
                                </li>
                                <li className='my-2'>
                                    <a className='text-decoration-none text-light' href="/reset_password">Reestablecer mi contraseña de MyQrAlert ➤</a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-12 col-md-4 links-group">
                            <span className='fs-4 my-4 fw-bold text-orange'>myqralert.com</span>
                            <ul className='my-4'>
                                <li className='my-2'>
                                    <a className='text-decoration-none text-light my-2' href="http://link.mercadopago.com.mx/plaquitasqr" target={"_blank"} rel={"noreferrer noopener"}>Donaciones ➤</a>
                                </li>
                                <li className='my-2'>
                                    <a className='text-decoration-none text-light my-2' href="#">Acerca de</a>
                                </li>
                                <li className='my-2'>
                                    <a className='text-decoration-none text-light' href="#">Tel:55 8462 7932</a>
                                </li>
                                <li className='my-2'>
                                    <a className='text-decoration-none text-light' href="/privacidad">Aviso de privacidad</a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                {/* Firma */}
                <div className="p-4 text-center">
                    <a className='text-decoration-none text-light' href="https://www.desarrollalab.com" target="_blank">Desarrollado por Desarrollalab - Soluciones Tecnológicas</a>.
                </div>
                {/* Ends firma */}
            </footer>
        </main>

    )
}
