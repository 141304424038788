import React from 'react'

export default function WaFloatButton() {
    return (
        <a href="https://api.whatsapp.com/send?phone=+525584627932&text=Hola, quiero más información sobre: " class="btn float"
            target="_blank">
            <i className="m-whatsapp wp-button"></i>
        </a>
    )
}
