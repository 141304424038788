/**React imports */
import React, { useEffect, useState } from 'react';
/**Router */
import { useNavigate, useParams } from 'react-router-dom';
/**Css styles */
import '../Navigation.css';
//importing icons
import whatsAppIcon from '../assets/icons/whatsAppIcon.svg';
import WaFloatButton from './WAFloatButton';

const Demo = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (

        <>



            <div className="containerDashboard">

                <WaFloatButton></WaFloatButton>

                {/* Foto */}

                <h2 className='userNameText'>Detalles de Jeff White</h2>

                <div className="containerPersonPhoto" id="row-pc">
                    <div style={{ backgroundImage: `url(https://randomuser.me/api/portraits/men/35.jpg)`, borderRadius: "50%", width: "200px", height: "200px" }} className="pet-circle-image-details shadow my-2"></div>
                </div>


                <div className="shadow-table my-4" id='row-mb'>
                    <div style={{ backgroundImage: `url(https://randomuser.me/api/portraits/men/35.jpg)`, borderRadius: "50%", width: "200px", height: "200px" }} className="pet-circle-image-details shadow my-2"></div>
                    <center>
                        <div className='containerNameUserNameCard'>
                            <span className='nameUserCard'>
                                Jeff White
                            </span>
                        </div>
                    </center>
                </div>

                {/* Información vital */}
                <div className="col my-4 bg-warning rounded">
                    <div class="accordion-body">
                        <p className='parrafAlert'>
                            <h1><strong>SORDOMUDO</strong></h1>
                        </p>
                    </div>
                </div>

                {/* Fin información vital */}

                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2">

                    <div className="col my-4">
                        <div class="accordion-item containerButtonAcordion">
                            <h2 class="accordion-header" id="headingOne">
                                <button class="buttonCardAcordion accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Ver datos generales
                                </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div class="accordion-body">

                                    <div className='m-2'>
                                        <span className='titleText'>Nombre: </span><span className='dataText'>Jeff White</span>
                                    </div>
                                    <div className='m-2'>
                                        <span className='titleText'>Dirección: </span><span className='dataText'>7509 Miller Ave</span>
                                    </div>
                                    <div className='m-2'>
                                        <span className='titleText'>País: </span><span className='dataText'>México</span>
                                    </div>
                                    <div className='m-2'>
                                        <span className='titleText'>Ciudad: </span><span className='dataText'>CDMX</span>
                                    </div>

                                    <div className='m-2'>
                                        <span className='titleText'>C.P.: </span><span className='dataText'>7509</span>
                                    </div>


                                    <div className='m-2'>
                                        <span className='titleText'>Tel. Móvil: </span><span className='dataText'>(787) 300-1757</span>
                                    </div>

                                    <div className='m-2'>
                                        <span className='titleText'>Tel. Oficina: </span><span className='dataText'>(838) 894-4195</span>
                                    </div>

                                    <div className='m-2'>
                                        <span className='titleText'>Tel. Casa: </span><span className='dataText'>(470) 439-0325</span>
                                    </div>

                                    <div className='m-2'>
                                        <span className='titleText'>E-mail: </span><span className='dataText'>jeft@white.com</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="col my-4">
                        <div class="accordion-item containerButtonAcordion">
                            <h2 class="accordion-header" id="headingTwo">
                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Avisar a
                                </button>
                            </h2>
                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                <div class="accordion-body">


                                    <>
                                        <div className="row row-cols-2">
                                            <div className="col">
                                                <div className='m-2'>
                                                    <span className='titleText'>Nombre: </span><span className='dataText'>Jar Frazier</span>
                                                </div>
                                                <div className='m-2'>
                                                    <span className='titleText'>Telefono: </span><a target={"_blank"} href={`tel:(826) 806-6976`} className='dataText'>(826) 806-6976</a>
                                                </div>
                                                <div className='m-2'>
                                                    <span className='titleText'>Teléfono alternativo: </span><a target={"_blank"} href={`tel:(826) 866-1002`} className='dataText'>(826) 866-1002</a>
                                                </div>
                                            </div>
                                            <div className="col">
                                                <a target={"_blank"} href={`https://api.whatsapp.com/send?phone=(826) 866-1002`}>
                                                    <img src={whatsAppIcon} className="whatsAppIcon" alt="" />
                                                </a>
                                            </div>
                                        </div>
                                    </>



                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col my-4">
                        <div class="accordion-item containerButtonAcordion">
                            <h2 class="accordion-header" id="headingThree">
                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Datos personales
                                </button>
                            </h2>
                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                <div class="accordion-body">

                                    <div className='m-2'>
                                        <span className='titleText'>Fecha de nacimiento: </span><span className='dataText'>11/4/1972</span>
                                    </div>
                                    <div className='m-2'>
                                        <span className='titleText'>Peso (kg): </span><span className='dataText'>74</span>
                                    </div>
                                    <div className='m-2'>
                                        <span className='titleText'>Altura (m): </span><span className='dataText'>1.70</span>
                                    </div>
                                    <div className='m-2'>
                                        <span className='titleText'>Grupo Sangúineo: </span><span className='dataText'>RH O+</span>
                                    </div>

                                    <div className='m-2'>
                                        <span className='titleText'>Donante de Órganos: </span><span className='dataText'>NO</span>
                                    </div>


                                    <div className='m-2'>
                                        <span className='titleText'>Donante de Sangre: </span><span className='dataText'>NO</span>
                                    </div>

                                    <div className='m-2'>
                                        <span className='titleText'>Número de la Seguridad Social: </span><span className='dataText'>10004627849</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col my-4">
                        <div class="accordion-item containerButtonAcordion">
                            <h2 class="accordion-header" id="headingFour">
                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Historial clínico
                                </button>
                            </h2>
                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                <div class="accordion-body">

                                    <div className='m-2'>
                                        <span className='titleText'>Religión: </span><span className='dataText'>Cristiana</span>
                                    </div>
                                    <div className='m-2'>
                                        <span className='titleText'>Alcohol: </span><span className='dataText'>Nada</span>
                                    </div>
                                    <div className='m-2'>
                                        <span className='titleText'>Tabaco </span><span className='dataText'>Nada</span>
                                    </div>
                                    <div className='m-2'>
                                        <span className='titleText'>Seguro: </span><span className='dataText'>AXA</span>
                                    </div>

                                    <div className='m-2'>
                                        <span className='titleText'>Nº de póliza: </span><span className='dataText'>11030483948</span>
                                    </div>

                                    <div className='m-2'>
                                        <span className='titleText'>Teléfono de la compañía: </span><span className='dataText'>(830) 903-7257</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col my-4">
                        <div class="accordion-item containerButtonAcordion">
                            <h2 class="accordion-header" id="headingFive">
                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    Condiciones médicas
                                </button>
                            </h2>
                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                <div class="accordion-body">

                                    <div className='m-2'>
                                        <span className='titleText'>Enfermedades: </span><span className='dataText'>Asma</span>
                                    </div>
                                    <div className='m-2'>
                                        <span className='titleText'>Vacunas: </span><span className='dataText'>Todas</span>
                                    </div>
                                    <div className='m-2'>
                                        <span className='titleText'>Alergias </span><span className='dataText'>Penicilina</span>
                                    </div>
                                    <div className='m-2'>
                                        <span className='titleText'>Problemas de circulación sanguínea: </span><span className='dataText'>NO</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col my-4">
                        <div class="accordion-item containerButtonAcordion">
                            <h2 class="accordion-header" id="headingSix">
                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    Médicos
                                </button>
                            </h2>
                            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                <div class="accordion-body">

                                    <>
                                        <div className="container">

                                            <div className="row row-cols-1">
                                                <div className="col">
                                                    <div className='m-2'>
                                                        <span className='titleText'>Nombre: </span><span className='dataText'>Harold Romero</span>
                                                    </div>
                                                    <div className='m-2'>
                                                        <span className='titleText'>Especialidad: </span><span className='dataText'>Médico cirujano</span>
                                                    </div>
                                                    <div className='m-2'>
                                                        <span className='titleText'>Telefono: </span><span className='dataText'>(755) 237-0583</span>
                                                    </div>
                                                    <div className='m-2'>
                                                        <span className='titleText'>Dirección: </span><span className='dataText'>8605 Cackson St</span>
                                                    </div>
                                                    <div className='m-2'>
                                                        <span className='titleText'>Ciudad: </span><span className='dataText'>CDMX</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='divider'></div>
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col my-4">
                        <div class="accordion-item containerButtonAcordion">
                            <h2 class="accordion-header" id="headingSeven">
                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    Medicación
                                </button>
                            </h2>
                            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                <div class="accordion-body">
                                    <>
                                        <div className="container">

                                            <div className="row row-cols-1">
                                                <div className="col">
                                                    <div className='m-2'>
                                                        <span className='titleText'>Medicamento: </span><span className='dataText'>Omalizumab</span>
                                                    </div>
                                                    <div className='m-2'>
                                                        <span className='titleText'>Dosis: </span><span className='dataText'>200</span>
                                                    </div>
                                                    <div className='m-2'>
                                                        <span className='titleText'>Frecuencia: </span><span className='dataText'>Cada semana</span>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                        <div className='divider'></div>
                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </>


    );

}


export default Demo;