/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { useSearchParams, useParams, useNavigate, Link } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';
import whatsAppIcon from '../../assets/icons/whatsAppIcon.svg';
// Importing firebase
import firebase from 'firebase/compat/app';
/**Transitions */
import app from '../../firebase';
import FileUploader from './FileUploader';
import SubscriptionsContainer from './subscriptionsCard/SubscriptionsContainer';
import Swal from 'sweetalert2';

const db = app.firestore();


const PersonDetails = () => {

    // Params from router props
    let params = useParams();
    // field value
    const fieldValue = firebase.firestore.FieldValue;
    // Navigation
    let navigate = useNavigate();


    const [personData, setPersonData] = useState({});
    const [personId, setPersonId] = useState('');

    // States
    const [updateState, setUpdateState] = useState({});
    const [isLoading, setIsloading] = useState(true)
    // States of contacts
    const [contactState, setContactState] = useState({});
    const [individualContactState, setIndividualContactState] = useState({})
    const [currentContact, setCurrentContact] = useState({});
    //States of medics
    const [medicState, setMedicState] = useState({})
    const [individualMedicoState, setIndividualMedicoState] = useState({})
    const [currentMedic, setCurrentMedic] = useState({});
    //States of medicamentos
    const [medicamentoState, setMedicamentoState] = useState({})
    const [individualMedicamentoState, setIndividualMedicamentoState] = useState({})
    const [currentMedicamento, setCurrentMedicamento] = useState({});
    // States of alergias
    const [alergiaState, setAlergiaState] = useState({});
    const [individualAlergiaState, setIndividualAlergiaState] = useState({})
    const [currentAlergia, setCurrentAlergia] = useState({});

    const [showSubscriptionCard, setShowSubscriptionCard] = useState(false)

    // Functions to restart tag
    const restartTag = () => {
        if (window.confirm("¿Realmente deseas reiniciar el Tag?")) {
            db.collection('persons').doc(personId).delete().then(() => {
                updateTagIdIsActive();
            })
        }

    }

    const updateTagIdIsActive = async () => {

        await db.collection("tags").where("tagId", "==", params.personId)
            .get()
            .then(function (querySnapshot) {
                querySnapshot.forEach(function (doc) {
                    // doc.data() is never undefined for query doc snapshots
                    db.collection('tags').doc(doc.id).update({
                        "isActive": false,
                    }).then(() => {
                        alert('Tag reinciado exitosamente');
                        console.log('Completed..');
                        window.location.href = "/cl/all_tags";

                    })
                });
            })

    }


    // const [fileLink, setFileLink] = useState('');
    const updatePhoto = async () => {

        let fileLink = localStorage.getItem("@fileLink")

        if (fileLink == null) {
            alert("No se ha seleccionado ninguna foto")
        } else {
            await db.collection('persons').doc(personId).update({
                "personPhotoImageLink": fileLink,
            }).then(() => {
                alert("Foto actualizada")
                localStorage.removeItem("@fileLink")
            }).catch((error) => {
                alert("No se pudo actualizar la foto, intente más tarde, código de error ", error);
            })
        }
    }
    // Handlers text
    const handleChangeUpdateText = (name, value) => {
        setUpdateState({ ...updateState, [name]: value })
    }

    // Handlers of contacts
    const handleChangeContactUpdateText = (name, value) => {
        setContactState({ ...contactState, [name]: value })
    }
    const handleChangeIndividualContactUpdateText = (name, value) => {
        setIndividualContactState({ ...individualContactState, [name]: value })
    }
    // Handlers of alergias
    const handleChangeAlergiaUpdateText = (name, value) => {
        setAlergiaState({ ...alergiaState, [name]: value })
    }
    const handleChangeIndividualAlergiaUpdateText = (name, value) => {
        setIndividualAlergiaState({ ...individualAlergiaState, [name]: value })
    }

    // Handlers of medic
    const handleChangeMedicState = (name, value) => {
        setMedicState({ ...medicState, [name]: value })
    }
    const handleIndividualMedicUpdateText = (name, value) => {
        setIndividualMedicoState({ ...individualMedicoState, [name]: value })
    }

    // Handlers of medicamento
    const handleChangeMedicamentoState = (name, value) => {
        setMedicamentoState({ ...medicamentoState, [name]: value })
    }
    const handleIndividualMedicamentoUpdateText = (name, value) => {
        setIndividualMedicamentoState({ ...individualMedicamentoState, [name]: value })
    }




    const updatePersonData = async () => {

        await db.collection("persons").doc(personId).set(updateState, {
            merge: true
        }).then(() => {
            setUpdateState({});
            alert("Información actualizada correctamente");
        }).catch(() => {
            setUpdateState({});
            alert("No se pudo actualizar la información, intente nuevamente");
        })
    }

    // update contact data to add "avisarA"
    const updateContactPerson = async () => {
        await db.collection('persons').doc(personId).update({ avisarA: fieldValue.arrayUnion(contactState) }, { merge: true })
            .then(async () => {
                alert("Contacto agregado correctamente");
                window.location.reload();
            }).catch(() => {
                alert("No fue posible agregar el contacto, intente nuevamente");
            })
    }


    // update Alergias data to add "AlergiasList"

    const updateAlergiasListaPerson = async () => {
        await db.collection('persons').doc(personId).update({ alergiasLista: fieldValue.arrayUnion(alergiaState) }, { merge: true })
            .then(async () => {
                alert("Alergia registrada correctamente");
                window.location.reload();
            }).catch(() => {
                alert("No fue posible registrar la alergia, intente nuevamente");
            })
    }

    // update individual contact

    const updateIndividualContact = async () => {

        await db.collection('persons').doc(personId).update({ avisarA: fieldValue.arrayRemove(currentContact) }, { merge: true })
            .then(async () => {
                await db.collection('persons').doc(personId).update({ avisarA: fieldValue.arrayUnion(individualContactState) }, { merge: true })
                    .then(() => {
                        alert("Contacto actualizado correctamente");
                        window.location.reload();
                    })
            }).catch(() => {
                alert("Error", "No fue posible actualizar el contacto, intente nuevamente");
            })
    }
    // update individual alergia

    const updateIndividualAlergia = async () => {

        await db.collection('persons').doc(personId).update({ alergiasLista: fieldValue.arrayRemove(currentAlergia) }, { merge: true })
            .then(async () => {
                await db.collection('persons').doc(personId).update({ alergiasLista: fieldValue.arrayUnion(individualAlergiaState) }, { merge: true })
                    .then(() => {
                        alert("Información actualizada correctamente");
                        window.location.reload();
                    })
            }).catch(() => {
                alert("Error", "No fue posible actualizar la información, intente nuevamente");
            })
    }

    // delete individual contact
    const deleteIndividualContact = async (contact) => {

        if (window.confirm("¿Desea eliminar el contacto?")) {
            await db.collection('persons').doc(personId).update({ avisarA: fieldValue.arrayRemove(contact) }, { merge: true })
                .then(async () => {
                    alert("Contacto eliminado correctamente");
                }).catch(() => {
                    alert("No fue posible eliminar el contacto, intente nuevamente");
                })
        } else {
            console.log("Cancelled")
        }

    }
    // delete individual alergia
    const deleteIndividualAlergia = async (alergia) => {

        if (window.confirm("¿Desea eliminar la alergia?")) {
            await db.collection('persons').doc(personId).update({ alergiasLista: fieldValue.arrayRemove(alergia) }, { merge: true })
                .then(async () => {
                    alert("Alergia eliminada correctamente");
                }).catch(() => {
                    alert("No fue posible eliminar la alergia, intente nuevamente");
                })
        } else {
            console.log("Cancelled")
        }

    }

    // update medic data to add "medicos"
    const updateMedicsPerson = async () => {
        await db.collection('persons').doc(personId).update({ medicos: fieldValue.arrayUnion(medicState) }, { merge: true })
            .then(async () => {
                alert("Médico agregado correctamente");
                window.location.reload();
            }).catch(() => {
                alert("No fue posible agregar el médico, intente nuevamente");
            })
    }

    // update individual medic

    const updateIndividualMedicData = async () => {

        await db.collection('persons').doc(personId).update({ medicos: fieldValue.arrayRemove(currentMedic) }, { merge: true })
            .then(async () => {
                await db.collection('persons').doc(personId).update({ medicos: fieldValue.arrayUnion(individualMedicoState) }, { merge: true })
                    .then(() => {
                        alert("Médico actualizado correctamente");
                        window.location.reload();
                    })
            }).catch(() => {
                alert("No fue posible actualizar el médico, intente nuevamente");
            })
    }

    // delete individual medic
    const deleteIndividualMedic = async (contact) => {

        if (window.confirm("¿Desea eliminar el médico?")) {
            await db.collection('persons').doc(personId).update({ medicos: fieldValue.arrayRemove(contact) }, { merge: true })
                .then(async () => {
                    setCurrentMedic({});
                    setMedicState({});
                    alert("Médico eliminado correctamente");
                }).catch(() => {
                    alert("No fue posible eliminar el médico, intente nuevamente");
                })
        } else {
            console.log("Cancelled");
        }

    }

    // update medic data to add "medicos"
    const updateMedicamentoPerson = async () => {
        await db.collection('persons').doc(personId).update({ medicacion: fieldValue.arrayUnion(medicamentoState) }, { merge: true })
            .then(async () => {
                alert("Medicamento agregado correctamente");
                window.location.reload();
            }).catch(() => {
                alert("No fue posible agregar el médico, intente nuevamente");
            })
    }

    // update individual medicamento

    const updateIndividualMedicamento = async () => {

        await db.collection('persons').doc(personId).update({ medicacion: fieldValue.arrayRemove(currentMedicamento) }, { merge: true })
            .then(async () => {
                await db.collection('persons').doc(personId).update({ medicacion: fieldValue.arrayUnion(individualMedicamentoState) }, { merge: true })
                    .then(() => {
                        alert("Medicamento actualizado correctamente");
                        window.location.reload();
                    })
            }).catch(() => {
                alert("No fue posible actualizar el medicamento, intente nuevamente");
            })
    }

    // delete individual medicamento
    const deleteIndividualMedicamento = async (medicamento) => {

        if (window.confirm("¿Desea eliminar el medicamento?")) {
            await db.collection('persons').doc(personId).update({ medicacion: fieldValue.arrayRemove(medicamento) }, { merge: true })
                .then(async () => {
                    setCurrentMedicamento({});
                    setMedicamentoState({});
                    alert("Medicamento eliminado correctamente");
                }).catch(() => {
                    alert("No fue posible eliminar el medicamento, intente nuevamente");
                })
        } else {
            console.log("Cancelled");
        }

    }

    // Update is visible general data

    const changeIsVisibleGeneralData = async (value) => {
        await db.collection("persons").doc(personId).set({
            "visibleDatosGenerales": value
        }, {
            merge: true
        }).then(() => {
            setUpdateState({});
            alert("Información actualizada correctamente");
        }).catch(() => {
            setUpdateState({});
            alert("No se pudo actualizar la información, intente nuevamente");
        })
    }

    // update is visible avisarA

    const changeIsVisibleAvisarA = async (value) => {
        await db.collection("persons").doc(personId).set({
            "visibleAvisarA": value
        }, {
            merge: true
        }).then(() => {
            setUpdateState({});
            alert("Información actualizada correctamente");
        }).catch(() => {
            setUpdateState({});
            alert("No se pudo actualizar la información, intente nuevamente");
        })
    }

    // update is visible personal data
    const changeIsVisiblePersonalData = async (value) => {
        await db.collection("persons").doc(personId).set({
            "visiblePersonalData": value
        }, {
            merge: true
        }).then(() => {
            setUpdateState({});
            alert("Información actualizada correctamente");
        }).catch(() => {
            setUpdateState({});
            alert("No se pudo actualizar la información, intente nuevamente");
        })
    }

    // visible historial clinico
    const changeIsVisibleHistorialClinico = async (value) => {
        await db.collection("persons").doc(personId).set({
            "visibleHistorialClinico": value
        }, {
            merge: true
        }).then(() => {
            setUpdateState({});
            alert("Información actualizada correctamente");
        }).catch(() => {
            setUpdateState({});
            alert("No se pudo actualizar la información, intente nuevamente");
        })
    }

    // visible condiciones medicas
    const changeIsVisibleCondicionesMedicas = async (value) => {
        await db.collection("persons").doc(personId).set({
            "visibleCondicionesMedicas": value
        }, {
            merge: true
        }).then(() => {
            setUpdateState({});
            alert("Información actualizada correctamente");
        }).catch(() => {
            setUpdateState({});
            alert("No se pudo actualizar la información, intente nuevamente");
        })
    }
    // visible condiciones alergias
    const changeIsVisibleAlergias = async (value) => {
        await db.collection("persons").doc(personId).set({
            "visibleAlergias": value
        }, {
            merge: true
        }).then(() => {
            setUpdateState({});
            alert("Información actualizada correctamente");
        }).catch(() => {
            setUpdateState({});
            alert("No se pudo actualizar la información, intente nuevamente");
        })
    }

    // visible medicos
    const changeIsVisibleMedicos = async (value) => {
        await db.collection("persons").doc(personId).set({
            "visibleMedicos": value
        }, {
            merge: true
        }).then(() => {
            setUpdateState({});
            alert("Información actualizada correctamente");
        }).catch(() => {
            setUpdateState({});
            alert("No se pudo actualizar la información, intente nuevamente");
        })
    }

    // visible medicacion
    const changeIsVisibleMedicacion = async (value) => {
        await db.collection("persons").doc(personId).set({
            "visibleMedicacion": value
        }, {
            merge: true
        }).then(() => {
            setUpdateState({});
            alert("Información actualizada correctamente");
        }).catch(() => {
            setUpdateState({});
            alert("No se pudo actualizar la información, intente nuevamente");
        })
    }

    // Change is visible informacionVital
    const changeIsVisibleInformacionVital = async (value) => {
        await db.collection("persons").doc(personId).set({
            "visibleInformacionVital": value
        }, {
            merge: true
        }).then(() => {
            setUpdateState({});
            alert("Información actualizada correctamente");
        }).catch(() => {
            setUpdateState({});
            alert("No se pudo actualizar la información, intente nuevamente");
        })
    }



    //Functions subscriptions

    const [plan, setPlan] = useState('')
    const [diasVigentes, setDiasVigentes] = useState(null)



    const convertToISODate = (daysToAdd = 0) => {
        const today = new Date();

        // Si se proporciona un número de días, suma esa cantidad de días a la fecha actual
        const futureDate = new Date(today.setDate(today.getDate() + daysToAdd));

        // Formatea la fecha en el formato ISO deseado
        const isoDate = futureDate.toISOString().split('T')[0];
        return isoDate;
    };

    const updateToProDemo = (tagId) => {

        Swal.fire({
            title: `Activa tu prueba PRO`,
            text: `Terminará ${convertToISODate(7)}, ¿Deseas continuar?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "No, más adelante",
            confirmButtonText: "Sí activar PRO",
            reverseButtons: true,
            customClass: {
                cancelButton: "btn-secondary-2" // Aquí puedes agregar la clase deseada al botón de cancelar
            }
        }).then((result) => {
            if (result.isConfirmed) {


                const isoStartDate = convertToISODate();
                const isoEndDate = convertToISODate(7); // Sumar 7 días a la fecha actual

                // Buscar el documento con el tagId proporcionado
                db.collection('persons').where("tagId", "==", tagId).get()
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            // Actualizar los campos especificados con merge true
                            db.collection('persons').doc(doc.id).set({
                                'showTryForFree': false,
                                'demoPro': true,
                                'fechaInicioSuscripcion': isoStartDate,
                                'fechaFinSuscripcion': isoEndDate,
                                'hasActivedPro': true
                            }, { merge: true })
                                .then(() => {
                                    Swal.fire({
                                        title: "¡Listo!",
                                        text: `Haz activado tu prueba PRO para ${personData.name}, disfruta de todas las herramientas que pensamos para tu familiar.`,
                                        icon: "success",
                                        confirmButtonText: "Aceptar"
                                    });
                                })
                                .catch((error) => {
                                    Swal.fire({
                                        title: "Ops!",
                                        text: "No fue posible activar tu prueba PRO por favor contacta a soporte técnico. Código de erorr" + error,
                                        icon: "error"
                                    });
                                });
                        });
                    })
                    .catch((error) => {
                        console.error("Error al buscar el tagId en la colección pets:", error);
                    });
            }
        });
    };


    function calcularDiasRestantes(fechaObjetivo) {

        // SI RETORNA NUMEROS SON LOS DIAS QUE QUEDAN DE LA SUSCRIPCIÓN
        // SI RETORNA FALSE SIGUE VIGENTE POR QUE AUN 
        // SI RETORNA limit hoy es el limite

        // Convierte la fecha objetivo a un objeto Date
        const fechaObjetivoDate = new Date(fechaObjetivo);

        // Obtiene la fecha actual
        const fechaHoy = new Date();

        // Compara las fechas
        if (fechaHoy > fechaObjetivoDate) {
            return false;
        } else if (fechaHoy.toDateString() === fechaObjetivoDate.toDateString()) {
            return "limit"
        } else {
            // Calcula los milisegundos de diferencia entre las fechas
            const diferenciaMilisegundos = fechaObjetivoDate - fechaHoy;
            // Convierte los milisegundos a días
            const diasRestantes = Math.floor(diferenciaMilisegundos / (1000 * 60 * 60 * 24));
            return diasRestantes
        }
    }
    //end functions subscriptions



    useEffect(() => {

        let userIdAuth = sessionStorage.getItem('userId');

        if (!userIdAuth) {
            navigate('/403')
        }

        db.collection('persons').where("tagId", "==", params.personId).onSnapshot((querySnapshot) => {

            querySnapshot.docs.forEach((doc) => {
                setPersonId(doc.id);
                setPersonData(doc.data())


                var auxData = doc.data();

                switch (true) {
                    case auxData.subscription?.subscriptionStatus === 'ACTIVE' && auxData.subscription.subscription_nextBillingTime:
                        const fechaObjetivoActive = auxData.subscription.subscription_nextBillingTime;
                        const mensajeActive = calcularDiasRestantes(fechaObjetivoActive);

                        console.log("fechaObjetivo ", fechaObjetivoActive);
                        setDiasVigentes((mensajeActive + 1));
                        setPlan(auxData.plan);
                        break;

                    case auxData.demoPro === true:
                        const fechaObjetivoDemoPro = auxData.fechaFinSuscripcion;

                        if (fechaObjetivoDemoPro) {
                            const mensajeDemoPro = calcularDiasRestantes(fechaObjetivoDemoPro);

                            if (mensajeDemoPro === false) {
                                setPlan("Demo");
                            } else {
                                setPlan("Pro");
                                setDiasVigentes((mensajeDemoPro + 1));
                            }
                        } else {
                            setPlan("Demo");
                        }
                        break;

                    case auxData.subscription?.subscriptionStatus === 'FALSE':
                        const fechaObjetivoFalse = auxData.subscription.subscription_nextBillingTime;

                        if (fechaObjetivoFalse) {
                            const mensajeFalse = calcularDiasRestantes(fechaObjetivoFalse);

                            if (mensajeFalse === false) {
                                setPlan("Demo");
                                console.log(mensajeFalse);
                            } else {
                                setPlan("Pro");
                                console.log("SE CAMBIO A PRO POR VIGENCIA DE SUSCRIPCION DE: ", mensajeFalse);
                                setDiasVigentes((mensajeFalse + 1));
                            }
                        } else {
                            console.log("Fecha objetivo no definida");
                            setPlan("Demo");
                        }
                        break;

                    case auxData.subscription === undefined:
                        setPlan("Demo");
                        break;

                    case auxData.plan === "Demo":
                        setPlan("Demo");
                        break;

                    default:
                        // Manejo por defecto, por ejemplo, si ninguna condición se cumple
                        break;
                }


                setIsloading(false);
            });
        })
    }, [])


    return (
        <>
            {

                isLoading == true ?
                    <>
                        <div className='containerDashboard'>
                            <div class="spinner-border text-dark" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </>

                    :



                    <>

                        <div className='containerDashboard'>

                            {
                                diasVigentes !== null ?
                                    <div className="my-4 bg-dark w-50 p-2">
                                        <h6 className='text-warning'>Importante: tu suscripción PRO vencerá {diasVigentes == 1 ? "Hoy" : `en ${diasVigentes} días`}</h6>
                                    </div>
                                    :
                                    null
                            }


                            {/* Foto */}


                            <div className='container'>
                                <div className="row row-cols-2">
                                    <div className="col">
                                        <h2 className='fs-1 fw-bold my-4 text-start' id="row-pc">Detalles de</h2>
                                        <Link to={"/cl/all_tags"} style={{ background: "#e58751", borderRadius: "10px" }} className="btn text-light my-3 p-2">{'<'} Atrás</Link>
                                    </div>
                                    <div className="col">
                                        <h2 className='userNameText mx-4'>
                                            {personData.nombre}
                                        </h2>
                                    </div>
                                </div>
                            </div>



                            <div className="containerPersonPhoto" id="row-pc">

                                <div style={{ backgroundImage: `url(${personData.personPhotoImageLink})`, borderRadius: "50%", width: "200px", height: "200px" }} className="pet-circle-image-details shadow my-2"></div>

                                <div className='my-2 parrafAlert'>
                                    <h5 className='titleText'>Actualizar foto</h5>
                                    <FileUploader></FileUploader>
                                </div>

                                <div className='form-group my-2 d-flex justify-content-center'>
                                    <button onClick={() => updatePhoto()} className="btn btnMyProfile">Actualizar foto</button>
                                </div>
                            </div>


                            {
                                plan == "Demo" ?
                                    <div className="bg-healt-pro rounded-2xl shadow p-4">
                                        <div className="pro-filter">
                                            <h2 className='fw-bold text-center text-light fs-2 my-2 py-4'>Cámbiate a PRO</h2>
                                            <p className='fs-6 text-light text-center mb-3'>No pierdas los beneficios y todas las herramientas que cuidan de <span className='bg-warning text-white fw-bold'>{personData.nombre}</span>, actualiza tu plan a PRO para seguir utilizando las características PRO que ya conces</p>

                                            {
                                                !showSubscriptionCard ?
                                                    <div div className="my-1 justify-center">
                                                        <button onClick={() => setShowSubscriptionCard(!showSubscriptionCard)} style={{ background: "#fff", color: "#F26716", borderRadius: "40px" }} className='btn fs-6 my-1 fw-bold rounded-2xl shadow'>Conservar beneficios</button>
                                                    </div>
                                                    :
                                                    null
                                            }


                                            {
                                                showSubscriptionCard ?
                                                    <div className='fade-div'>
                                                        <div className="container">
                                                            <SubscriptionsContainer personId={personData.tagId} nombrePersona={personData.name} />
                                                            <div className="my-2">
                                                                <span className='text-secondary text-decoration-underline text-white' onClick={() => setShowSubscriptionCard(!showSubscriptionCard)}>Cancelar</span>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    :
                                                    null
                                            }

                                            {
                                                personData.showTryForFree ?

                                                    <div className="m-2 container-cardsubs">
                                                        <h4 className='fw-bold fs-5 text-center text-white'>¿No estás seguro de cómo comenzar?</h4>
                                                        <div>
                                                            <p className='text-center text-white mt-2 fs-xsm'>
                                                                Prueba 7 días de MYQRALERT PRO y prueba todas las herramientas en PRO de tu familiar
                                                            </p>
                                                        </div>

                                                        <div className="justify-center">
                                                            <button onClick={() => updateToProDemo(personData.tagId)} style={{ background: "#fff", color: "#F26716", borderRadius: "40px" }} className="btn fw-bold btn-full-rounded my-4">Activar prueba PRO</button>
                                                        </div>
                                                    </div>

                                                    :
                                                    null
                                            }

                                        </div>
                                    </div>
                                    :
                                    <div className="bannerPro p-2 rounded-2xl">
                                        <h3 className='fs-3 fw-bold'>Plan PRO</h3>
                                    </div>
                            }


                            <div className="container shadow-table p-3 my-4" id='row-mb'>
                                <center>
                                    <div style={{ backgroundImage: `url(${personData.personPhotoImageLink})`, borderRadius: "50%", width: "200px", height: "200px" }} className="pet-circle-image-details shadow my-2"></div>

                                    <div className="containerNameMb">
                                        <span className='nameUserCard'>
                                            {personData.nombre}
                                        </span>
                                    </div>

                                </center>
                                <div className='my-2 parrafAlert container '>
                                    <h5 className='titleText'>Actualizar foto</h5>
                                    <FileUploader></FileUploader>
                                </div>

                                <div className='form-group my-2 d-flex justify-content-center'>
                                    <button onClick={() => updatePhoto()} className="btn btnMyProfile">Actualizar foto</button>
                                </div>
                            </div>


                            <div className="container">
                                <div className='row row-cols-1 row-cols-sm-2 row-cols-md-2'>
                                    {/* Información vital */}
                                    <div className="col my-4">
                                        <div class="accordion-item containerButtonAcordion">
                                            <h2 class="accordion-header" id="headingNine">
                                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                    Información vital
                                                </button>
                                            </h2>
                                            <div id="collapseNine" class="accordion-collapse collapse" aria-labelledby="headingNine">
                                                <div class="accordion-body">

                                                    <div className='m-2 d-flex flex-row'>
                                                        <span className='titleText'>Es visible: </span>
                                                        <div class="form-check form-switch my-1">
                                                            <input class="form-check-input" type="checkbox" role="switch" defaultChecked={personData.visibleInformacionVital} onChange={(e) => changeIsVisibleInformacionVital(e.target.checked)}></input>
                                                        </div>
                                                    </div>

                                                    <p className='parrafAlert'>
                                                        Proporciona información vital de <strong>{personData.nombre}</strong>, de esta manera, las personas verán inmediatamente la información que coloques, por ejemplo: "Sordo, No habla"
                                                    </p>

                                                    <div className='form-group'>
                                                        <label className='mt-3'>Información vital</label>
                                                        <input defaultValue={personData.informacionVital} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("informacionVital", e.target.value)}></input>
                                                    </div>


                                                    {
                                                        plan == 'Demo' ?
                                                            <div className='form-group'>
                                                                <button onClick={() => alert("Función disponible sólo para usuarios nivel PRO")} className="btn btn-warning rounded-2xl fw-bold my-2">Actualizar información
                                                                    <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpro-badge-white-icon.svg?alt=media&token=e0b5c97f-cf24-4b6b-819f-7b2b8210994a" />
                                                                </button>
                                                            </div>
                                                            :
                                                            <div className='d-flex flex-row-reverse my-2'>
                                                                <button className='btn btnMyProfile' onClick={() => updatePersonData()}>Actualizar información</button>
                                                            </div>
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Fin información vital */}


                                    {/* Configuración de correos */}


                                    <div className="col my-4">
                                        <div class="accordion-item containerButtonAcordion">
                                            <h2 class="accordion-header" id="headingTen">
                                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                                    Correos de contacto
                                                </button>
                                            </h2>
                                            <div id="collapseTen" class="accordion-collapse collapse" aria-labelledby="headingTen">
                                                <div class="accordion-body">
                                                    <p className='parrafAlert'>
                                                        Ingresa correos de emergencia para {personData.nombre}, pueden ser padres, tutores o familiares
                                                    </p>

                                                    <div className='form-group'>
                                                        <label className='mt-3'>Ingresa correos, separados por coma (",")</label>
                                                        <textarea rows={6} defaultValue={personData.correosEmergencia} class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("correosEmergencia", e.target.value)}></textarea>
                                                    </div>

                                                    {
                                                        plan == 'Demo' ?
                                                            <div className='form-group'>
                                                                <button onClick={() => alert("Función disponible sólo para usuarios nivel PRO")} className="btn btn-warning rounded-2xl fw-bold my-2">Actualizar información
                                                                    <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpro-badge-white-icon.svg?alt=media&token=e0b5c97f-cf24-4b6b-819f-7b2b8210994a" />
                                                                </button>
                                                            </div>
                                                            :
                                                            <div className='d-flex flex-row-reverse my-2'>
                                                                <button className='btn btnMyProfile' onClick={() => updatePersonData()}>Actualizar información</button>
                                                            </div>
                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Fin configuración de correos */}
                                </div>
                            </div>


                            {/* Datos generales */}

                            <div className="container">


                                <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2">


                                    <div className="col my-4">
                                        <div class="accordion-item containerButtonAcordion">
                                            <h2 class="accordion-header" id="headingOne">
                                                <button class="buttonCardAcordion accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    Ver datos generales
                                                </button>
                                            </h2>
                                            <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">


                                                    {
                                                        plan == 'Demo' ?
                                                            <div className='m-2 d-flex flex-row'>
                                                                <span className='titleText'>Es visible: </span>
                                                                <div class="form-check form-switch my-1">
                                                                    <input class="form-check-input" type="checkbox" role="switch" checked={false} onChange={(e) => alert("Función disponible sólo para cuentas nivel PRO")}></input>
                                                                </div>
                                                            </div>


                                                            :
                                                            <div className='m-2 d-flex flex-row'>
                                                                <span className='titleText'>Es visible: </span>
                                                                <div class="form-check form-switch my-1">
                                                                    <input class="form-check-input" type="checkbox" role="switch" defaultChecked={personData.visibleDatosGenerales} onChange={(e) => changeIsVisibleGeneralData(e.target.checked)}></input>
                                                                </div>
                                                            </div>

                                                    }

                                                    <div className='m-2'>
                                                        <span className='titleText'>Nombre: </span><span className='dataText'>{personData.nombre}</span>
                                                    </div>
                                                    <div className='m-2'>
                                                        <span className='titleText'>Dirección: </span><span className='dataText'>{personData.direccion}</span>
                                                    </div>
                                                    <div className='m-2'>
                                                        <span className='titleText'>País: </span><span className='dataText'>{personData.pais}</span>
                                                    </div>
                                                    <div className='m-2'>
                                                        <span className='titleText'>Ciudad: </span><span className='dataText'>{personData.ciudad}</span>
                                                    </div>

                                                    <div className='m-2'>
                                                        <span className='titleText'>C.P.: </span><span className='dataText'>{personData.cp}</span>
                                                    </div>


                                                    <div className='m-2'>
                                                        <span className='titleText'>Tel. Móvil: </span><span className='dataText'>{personData.telMovil}</span>
                                                    </div>

                                                    <div className='m-2'>
                                                        <span className='titleText'>Tel. Oficina: </span><span className='dataText'>{personData.telOficina}</span>
                                                    </div>

                                                    <div className='m-2'>
                                                        <span className='titleText'>Tel. Casa: </span><span className='dataText'>{personData.telCasa}</span>
                                                    </div>


                                                    {
                                                        plan == 'Demo' ?
                                                            <div className='form-group'>
                                                                <button onClick={() => alert("Función disponible sólo para usuarios nivel PRO")} className="btn btn-warning rounded-2xl fw-bold my-2">Actualizar información
                                                                    <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpro-badge-white-icon.svg?alt=media&token=e0b5c97f-cf24-4b6b-819f-7b2b8210994a" />
                                                                </button>
                                                            </div>
                                                            :

                                                            <div className='d-flex flex-row-reverse'>
                                                                <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-target="#generalInformationModal">Editar</button>
                                                            </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col my-4">
                                        <div class="accordion-item containerButtonAcordion">
                                            <h2 class="accordion-header" id="headingTwo">
                                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    Avisar a
                                                </button>
                                            </h2>
                                            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">


                                                    {
                                                        plan == 'Demo' ?
                                                            <div className='m-2 d-flex flex-row'>
                                                                <span className='titleText'>Es visible: </span>
                                                                <div class="form-check form-switch my-1">
                                                                    <input class="form-check-input" type="checkbox" role="switch" checked={false} onChange={(e) => alert("Función disponible sólo para cuentas nivel PRO")}></input>
                                                                </div>
                                                            </div>


                                                            :

                                                            <div className='m-2 d-flex flex-row'>
                                                                <span className='titleText'>Es visible: </span>
                                                                <div class="form-check form-switch my-1">
                                                                    <input class="form-check-input" type="checkbox" role="switch" defaultChecked={personData.visibleAvisarA} onChange={(e) => changeIsVisibleAvisarA(e.target.checked)}></input>
                                                                </div>
                                                            </div>
                                                    }

                                                    {

                                                        personData.avisarA != undefined ?

                                                            personData.avisarA.map((contacto) => (
                                                                <>
                                                                    <div className="container" key={contacto.id}>

                                                                        <div className="row row-cols-2">
                                                                            <div className="col">
                                                                                <div className='m-2'>
                                                                                    <span className='titleText'>Nombre: </span><span className='dataText'>{contacto.nombre}</span>
                                                                                </div>
                                                                                <div className='m-2'>
                                                                                    <span className='titleText'>Telefono: </span><a target={"_blank"} href={`tel:${contacto.telefono}`} className='dataText text-success'>{contacto.telefono}</a>
                                                                                </div>
                                                                                <div className='m-2'>
                                                                                    <span className='titleText'>Teléfono alternativo: </span><a target={"_blank"} href={`tel:${contacto.telefonoAlternativo}`} className='dataText text-success'>{contacto.telefonoAlternativo}</a>
                                                                                </div>
                                                                                <button type="button" class="btn btn-secondary m-2" data-bs-toggle="modal" data-bs-target="#editContactModal" onClick={() => {
                                                                                    setCurrentContact(contacto);
                                                                                    setIndividualContactState(contacto)
                                                                                }}>Editar</button>
                                                                                <button className="btn btn-danger" onClick={() => deleteIndividualContact(contacto)}>Eliminar</button>
                                                                            </div>
                                                                            <div className="col">
                                                                                <a target={"_blank"} href={`https://api.whatsapp.com/send?phone=+52${contacto.telefono}`}>
                                                                                    <img src={whatsAppIcon} className="whatsAppIcon" alt="" />
                                                                                </a>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <div className='divider'></div>
                                                                </>
                                                            ))

                                                            :

                                                            <div className='divider'></div>
                                                    }



                                                    {
                                                        plan == 'Demo' ?
                                                            <div className='form-group'>
                                                                <button onClick={() => alert("Función disponible sólo para usuarios nivel PRO")} className="btn btn-warning rounded-2xl fw-bold my-2">Actualizar información
                                                                    <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpro-badge-white-icon.svg?alt=media&token=e0b5c97f-cf24-4b6b-819f-7b2b8210994a" />
                                                                </button>
                                                            </div>
                                                            :

                                                            <div className='d-flex flex-row-reverse my-2'>
                                                                <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-target="#addNewContactData">Nuevo contacto</button>
                                                            </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col my-4">
                                        <div class="accordion-item containerButtonAcordion">
                                            <h2 class="accordion-header" id="headingThree">
                                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    Datos personales
                                                </button>
                                            </h2>
                                            <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">

                                                    {
                                                        plan == 'Demo' ?
                                                            <div className='m-2 d-flex flex-row'>
                                                                <span className='titleText'>Es visible: </span>
                                                                <div class="form-check form-switch my-1">
                                                                    <input class="form-check-input" type="checkbox" role="switch" checked={false} onChange={(e) => alert("Función disponible sólo para cuentas nivel PRO")}></input>
                                                                </div>
                                                            </div>


                                                            :


                                                            <div className='m-2 d-flex flex-row'>
                                                                <span className='titleText'>Es visible: </span>
                                                                <div class="form-check form-switch my-1">
                                                                    <input class="form-check-input" type="checkbox" role="switch" defaultChecked={personData.visiblePersonalData} onChange={(e) => changeIsVisiblePersonalData(e.target.checked)}></input>
                                                                </div>
                                                            </div>
                                                    }

                                                    <div className='m-2'>
                                                        <span className='titleText'>Fecha de nacimiento: </span><span className='dataText'>{personData.fechaNacimiento}</span>
                                                    </div>
                                                    <div className='m-2'>
                                                        <span className='titleText'>Peso (kg): </span><span className='dataText'>{personData.peso}</span>
                                                    </div>
                                                    <div className='m-2'>
                                                        <span className='titleText'>Altura (m): </span><span className='dataText'>{personData.altura}</span>
                                                    </div>
                                                    <div className='m-2'>
                                                        <span className='titleText'>Grupo Sangúineo: </span><span className='dataText'>{personData.grupoSanguineo}</span>
                                                    </div>

                                                    <div className='m-2'>
                                                        <span className='titleText'>Donante de Órganos: </span><span className='dataText'>{personData.donanteOrganos}</span>
                                                    </div>


                                                    <div className='m-2'>
                                                        <span className='titleText'>Donante de Sangre: </span><span className='dataText'>{personData.donanteSangre}</span>
                                                    </div>

                                                    <div className='m-2'>
                                                        <span className='titleText'>Número de la Seguridad Social: </span><span className='dataText'>{personData.numeroSeguridadSocial}</span>
                                                    </div>


                                                    {
                                                        plan == 'Demo' ?
                                                            <div className='form-group'>
                                                                <button onClick={() => alert("Función disponible sólo para usuarios nivel PRO")} className="btn btn-warning rounded-2xl fw-bold my-2">Actualizar información
                                                                    <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpro-badge-white-icon.svg?alt=media&token=e0b5c97f-cf24-4b6b-819f-7b2b8210994a" />
                                                                </button>
                                                            </div>
                                                            :
                                                            <div className='d-flex flex-row-reverse'>
                                                                <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-target="#personalInformationModal">Editar</button>
                                                            </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col my-4">
                                        <div class="accordion-item containerButtonAcordion">
                                            <h2 class="accordion-header" id="headingFour">
                                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                    Historial clínico
                                                </button>
                                            </h2>
                                            <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">

                                                    {
                                                        plan == 'Demo' ?
                                                            <div className='m-2 d-flex flex-row'>
                                                                <span className='titleText'>Es visible: </span>
                                                                <div class="form-check form-switch my-1">
                                                                    <input class="form-check-input" type="checkbox" role="switch" checked={false} onChange={(e) => alert("Función disponible sólo para cuentas nivel PRO")}></input>
                                                                </div>
                                                            </div>


                                                            :

                                                            <div className='m-2 d-flex flex-row'>
                                                                <span className='titleText'>Es visible: </span>
                                                                <div class="form-check form-switch my-1">
                                                                    <input class="form-check-input" type="checkbox" role="switch" defaultChecked={personData.visibleHistorialClinico} onChange={(e) => changeIsVisibleHistorialClinico(e.target.checked)}></input>
                                                                </div>
                                                            </div>
                                                    }


                                                    <div className='m-2'>
                                                        <span className='titleText'>Religión: </span><span className='dataText'>{personData.religion}</span>
                                                    </div>
                                                    <div className='m-2'>
                                                        <span className='titleText'>Alcohol: </span><span className='dataText'>{personData.alcohol}</span>
                                                    </div>
                                                    <div className='m-2'>
                                                        <span className='titleText'>Tabaco </span><span className='dataText'>{personData.tabaco}</span>
                                                    </div>
                                                    <div className='m-2'>
                                                        <span className='titleText'>Seguro: </span><span className='dataText'>{personData.nombreSeguro}</span>
                                                    </div>

                                                    <div className='m-2'>
                                                        <span className='titleText'>Nº de póliza: </span><span className='dataText'>{personData.numeroPoliza}</span>
                                                    </div>

                                                    <div className='m-2'>
                                                        <span className='titleText'>Teléfono de la compañía: </span><span className='dataText'>{personData.telefonoDeSeguro}</span>
                                                    </div>


                                                    {
                                                        plan == 'Demo' ?
                                                            <div className='form-group'>
                                                                <button onClick={() => alert("Función disponible sólo para usuarios nivel PRO")} className="btn btn-warning rounded-2xl fw-bold my-2">Actualizar información
                                                                    <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpro-badge-white-icon.svg?alt=media&token=e0b5c97f-cf24-4b6b-819f-7b2b8210994a" />
                                                                </button>
                                                            </div>
                                                            :
                                                            <div className='d-flex flex-row-reverse'>
                                                                <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-target="#historialClinicoModal">Editar</button>
                                                            </div>

                                                    }


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col my-4">
                                        <div class="accordion-item containerButtonAcordion">
                                            <h2 class="accordion-header" id="headingFive">
                                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                    Condiciones médicas
                                                </button>
                                            </h2>
                                            <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    {
                                                        plan == 'Demo' ?
                                                            <div className='m-2 d-flex flex-row'>
                                                                <span className='titleText'>Es visible: </span>
                                                                <div class="form-check form-switch my-1">
                                                                    <input class="form-check-input" type="checkbox" role="switch" checked={false} onChange={(e) => alert("Función disponible sólo para cuentas nivel PRO")}></input>
                                                                </div>
                                                            </div>


                                                            :

                                                            <div className='m-2 d-flex flex-row'>
                                                                <span className='titleText'>Es visible: </span>
                                                                <div class="form-check form-switch my-1">
                                                                    <input class="form-check-input" type="checkbox" role="switch" defaultChecked={personData.visibleCondicionesMedicas} onChange={(e) => changeIsVisibleCondicionesMedicas(e.target.checked)}></input>
                                                                </div>
                                                            </div>
                                                    }

                                                    <div className='m-2'>
                                                        <span className='titleText'>Enfermedades: </span><span className='dataText'>{personData.enfermedades}</span>
                                                    </div>
                                                    <div className='m-2'>
                                                        <span className='titleText'>Vacunas: </span><span className='dataText'>{personData.vacunas}</span>
                                                    </div>
                                                    <div className='m-2'>
                                                        <span className='titleText'>Problemas de circulación sanguínea: </span><span className='dataText'>{personData.problemasCirculacion}</span>
                                                    </div>

                                                    {
                                                        plan == 'Demo' ?
                                                            <div className='form-group'>
                                                                <button onClick={() => alert("Función disponible sólo para usuarios nivel PRO")} className="btn btn-warning rounded-2xl fw-bold my-2">Actualizar información
                                                                    <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpro-badge-white-icon.svg?alt=media&token=e0b5c97f-cf24-4b6b-819f-7b2b8210994a" />
                                                                </button>
                                                            </div>
                                                            :
                                                            <div className='d-flex flex-row-reverse'>
                                                                <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-target="#condicionesMedicasModal">Editar</button>
                                                            </div>

                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col my-4">
                                        <div class="accordion-item containerButtonAcordion">
                                            <h2 class="accordion-header" id="headAlergias">
                                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseAlergias" aria-expanded="false" aria-controls="collapseAlergias">
                                                    Alergias
                                                </button>
                                            </h2>
                                            <div id="collapseAlergias" class="accordion-collapse collapse" aria-labelledby="headAlergias" data-bs-parent="#collapseAlergias">
                                                <div class="accordion-body">
                                                    {
                                                        plan == 'Demo' ?
                                                            <div className='m-2 d-flex flex-row'>
                                                                <span className='titleText'>Es visible: </span>
                                                                <div class="form-check form-switch my-1">
                                                                    <input class="form-check-input" type="checkbox" role="switch" checked={false} onChange={(e) => alert("Función disponible sólo para cuentas nivel PRO")}></input>
                                                                </div>
                                                            </div>


                                                            :

                                                            <div className='m-2 d-flex flex-row'>
                                                                <span className='titleText'>Es visible: </span>
                                                                <div class="form-check form-switch my-1">
                                                                    <input class="form-check-input" type="checkbox" role="switch" defaultChecked={personData.visibleAlergias} onChange={(e) => changeIsVisibleAlergias(e.target.checked)}></input>
                                                                </div>
                                                            </div>
                                                    }

                                                    {

                                                        personData.alergiasLista != undefined ?

                                                            personData.alergiasLista.map((alergia) => (
                                                                <>
                                                                    <div className="container" key={alergia.id}>

                                                                        <div className="row row-cols-1">
                                                                            <div className="col">
                                                                                <div className='m-2'>
                                                                                    <span className='titleText'>Alergia: </span><span className='dataText'>{alergia.alergia}</span>
                                                                                </div>
                                                                                <div className='m-2'>
                                                                                    <span className='titleText'>Reacción: </span> <span className='dataText'>{alergia.reaccion}</span>
                                                                                </div>
                                                                                <div className='m-2'>
                                                                                    <span className='titleText'>Tratamiento: </span><span className='dataText'>{alergia.tratamiento}</span>
                                                                                </div>
                                                                                <button type="button" class="btn btn-secondary m-2" data-bs-toggle="modal" data-bs-target="#editAlergiaModal" onClick={() => {
                                                                                    setCurrentAlergia(alergia);
                                                                                    setIndividualAlergiaState(alergia)
                                                                                }}>Editar</button>
                                                                                <button className="btn btn-danger" onClick={() => deleteIndividualAlergia(alergia)}>Eliminar</button>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <div className='divider'></div>
                                                                </>
                                                            ))

                                                            :

                                                            <div className='divider'></div>
                                                    }


                                                    {
                                                        plan == 'Demo' ?
                                                            <div className='form-group'>
                                                                <button onClick={() => alert("Función disponible sólo para usuarios nivel PRO")} className="btn btn-warning rounded-2xl fw-bold my-2">Actualizar información
                                                                    <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpro-badge-white-icon.svg?alt=media&token=e0b5c97f-cf24-4b6b-819f-7b2b8210994a" />
                                                                </button>
                                                            </div>
                                                            :
                                                            <div className='d-flex flex-row-reverse my-2'>
                                                                <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-target="#addNewAlergiaModal">Registrar alergia</button>
                                                            </div>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col my-4">
                                        <div class="accordion-item containerButtonAcordion">
                                            <h2 class="accordion-header" id="headingSix">
                                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                    Médicos
                                                </button>
                                            </h2>
                                            <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">

                                                    {
                                                        plan == 'Demo' ?
                                                            <div className='m-2 d-flex flex-row'>
                                                                <span className='titleText'>Es visible: </span>
                                                                <div class="form-check form-switch my-1">
                                                                    <input class="form-check-input" type="checkbox" role="switch" checked={false} onChange={(e) => alert("Función disponible sólo para cuentas nivel PRO")}></input>
                                                                </div>
                                                            </div>


                                                            :

                                                            <div className='m-2 d-flex flex-row'>
                                                                <span className='titleText'>Es visible: </span>
                                                                <div class="form-check form-switch my-1">
                                                                    <input class="form-check-input" type="checkbox" role="switch" defaultChecked={personData.visibleMedicos} onChange={(e) => changeIsVisibleMedicos(e.target.checked)}></input>
                                                                </div>
                                                            </div>

                                                    }

                                                    {

                                                        personData.medicos != undefined ?

                                                            personData.medicos.map((medico) => (
                                                                <>
                                                                    <div className="container" key={medico.id}>

                                                                        <div className="row row-cols-1">
                                                                            <div className="col">
                                                                                <div className='m-2'>
                                                                                    <span className='titleText'>Nombre: </span><span className='dataText'>{medico.nombre}</span>
                                                                                </div>
                                                                                <div className='m-2'>
                                                                                    <span className='titleText'>Especialidad: </span><span className='dataText'>{medico.especialidad}</span>
                                                                                </div>
                                                                                <div className='m-2'>
                                                                                    <span className='titleText'>Telefono: </span><span className='dataText'>{medico.telefonoMedico}</span>
                                                                                </div>
                                                                                <div className='m-2'>
                                                                                    <span className='titleText'>Dirección: </span><span className='dataText'>{medico.direccionMedico}</span>
                                                                                </div>
                                                                                <div className='m-2'>
                                                                                    <span className='titleText'>Ciudad: </span><span className='dataText'>{medico.ciudadMedico}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <button type="button" class="btn btn-secondary m-2" data-bs-toggle="modal" data-bs-target="#editIndividualMedic" onClick={() => {
                                                                                    setCurrentMedic(medico);
                                                                                    setIndividualMedicoState(medico)
                                                                                }}>Editar</button>
                                                                                <button className="btn btn-danger" onClick={() => deleteIndividualMedic(medico)}>Eliminar</button>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <div className='divider'></div>
                                                                </>
                                                            ))

                                                            :

                                                            <div className='divider'></div>
                                                    }

                                                    {
                                                        plan == 'Demo' ?
                                                            <div className='form-group'>
                                                                <button onClick={() => alert("Función disponible sólo para usuarios nivel PRO")} className="btn btn-warning rounded-2xl fw-bold my-2">Actualizar información
                                                                    <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpro-badge-white-icon.svg?alt=media&token=e0b5c97f-cf24-4b6b-819f-7b2b8210994a" />
                                                                </button>
                                                            </div>
                                                            :
                                                            <div className='d-flex flex-row-reverse my-2'>
                                                                <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-target="#openNewMedicModal">Nuevo médico</button>
                                                            </div>

                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col my-4">
                                        <div class="accordion-item containerButtonAcordion">
                                            <h2 class="accordion-header" id="headingSeven">
                                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                    Medicación
                                                </button>
                                            </h2>
                                            <div id="collapseSeven" class="accordion-collapse collapse" aria-labelledby="headingSeven" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">
                                                    {
                                                        plan == 'Demo' ?
                                                            <div className='m-2 d-flex flex-row'>
                                                                <span className='titleText'>Es visible: </span>
                                                                <div class="form-check form-switch my-1">
                                                                    <input class="form-check-input" type="checkbox" role="switch" checked={false} onChange={(e) => alert("Función disponible sólo para cuentas nivel PRO")}></input>
                                                                </div>
                                                            </div>


                                                            :

                                                            <div className='m-2 d-flex flex-row'>
                                                                <span className='titleText'>Es visible: </span>
                                                                <div class="form-check form-switch my-1">
                                                                    <input class="form-check-input" type="checkbox" role="switch" defaultChecked={personData.visibleMedicacion} onChange={(e) => changeIsVisibleMedicacion(e.target.checked)}></input>
                                                                </div>
                                                            </div>
                                                    }

                                                    {

                                                        personData.medicacion != undefined ?

                                                            personData.medicacion.map((medicamento) => (
                                                                <>
                                                                    <div className="container" key={medicamento.id}>

                                                                        <div className="row row-cols-1">
                                                                            <div className="col">
                                                                                <div className='m-2'>
                                                                                    <span className='titleText'>Medicamento: </span><span className='dataText'>{medicamento.nombre}</span>
                                                                                </div>
                                                                                <div className='m-2'>
                                                                                    <span className='titleText'>Dosis: </span><span className='dataText'>{medicamento.dosis}</span>
                                                                                </div>
                                                                                <div className='m-2'>
                                                                                    <span className='titleText'>Frecuencia: </span><span className='dataText'>{medicamento.frecuencia}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col">
                                                                                <button type="button" class="btn btn-secondary m-2" data-bs-toggle="modal" data-bs-target="#openModalEditIndividualMedicamento" onClick={() => {
                                                                                    setCurrentMedicamento(medicamento);
                                                                                    setIndividualMedicamentoState(medicamento)
                                                                                }}>Editar</button>
                                                                                <button className="btn btn-danger" onClick={() => deleteIndividualMedicamento(medicamento)}>Eliminar</button>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                    <div className='divider'></div>
                                                                </>
                                                            ))

                                                            :

                                                            <div className='divider'></div>
                                                    }


                                                    {
                                                        plan == 'Demo' ?
                                                            <div className='form-group'>
                                                                <button onClick={() => alert("Función disponible sólo para usuarios nivel PRO")} className="btn btn-warning rounded-2xl fw-bold my-2">Actualizar información
                                                                    <img className='mx-2' width={30} height={30} src="https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fpro-badge-white-icon.svg?alt=media&token=e0b5c97f-cf24-4b6b-819f-7b2b8210994a" />
                                                                </button>
                                                            </div>
                                                            :
                                                            <div className='d-flex flex-row-reverse my-2'>
                                                                <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-target="#openNewMedicamentoModal">Nuevo medicamento</button>
                                                            </div>

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div className="col my-4">
                                        <div class="accordion-item containerButtonAcordion">
                                            <h2 class="accordion-header" id="headingEight">
                                                <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                    Configuración del Tag
                                                </button>
                                            </h2>
                                            <div id="collapseEight" class="accordion-collapse collapse" aria-labelledby="headingEight" data-bs-parent="#accordionExample">
                                                <div class="accordion-body">


                                                    <h4 className='tagPersonName' style={{ color: "#ED4747" }}>Reiniciar tag</h4>
                                                    <p className='my-2'>
                                                        <strong>Debes contar con el TAG ID que corresponde a tu QR</strong>
                                                        <br />
                                                    </p>

                                                    <p className='parrafAlert'>
                                                        Al reiniciar el tag de <strong>{personData.nombre}</strong> podrás registrar otra persona en su lugar, con esto no tendrás que adquirir una nueva Placa
                                                    </p>


                                                    <div className='d-flex flex-row-reverse my-2'>
                                                        <button className='btn btn-danger' onClick={() => restartTag()}>Reiniciar Tag</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                </div>

                            </div>


                            <div style={{ height: "20px" }}></div>

                        </div>





                        {/* Modal editar información general */}

                        <div className='d-flex justify-content-center'>
                            <div class="modal fade" id="generalInformationModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modalCenter modal-dialog-scrollable">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h2 class="titleText">Editar información general</h2>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div className='mt-2'>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Nombre <span className='fieldRequired'>*</span></label>
                                                    <input defaultValue={personData.nombre} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("nombre", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Dirección</label>
                                                    <input defaultValue={personData.direccion} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("direccion", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Ciudad</label>
                                                    <input defaultValue={personData.ciudad} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("ciudad", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>País</label>
                                                    <input defaultValue={personData.pais} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("pais", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Nacionalidad</label>
                                                    <input defaultValue={personData.nacionalidad} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("nacionalidad", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>C.P.</label>
                                                    <input defaultValue={personData.cp} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("cp", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Tel. Móvil.</label>
                                                    <input defaultValue={personData.telMovil} type="number" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("telMovil", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Tel. Casa</label>
                                                    <input defaultValue={personData.telCasa} type="number" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("telCasa", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Tel. Oficina</label>
                                                    <input defaultValue={personData.telOficina} type="number" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("telOficina", e.target.value)}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary m-2" data-bs-dismiss="modal">Cancelar</button>
                                            <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={() => updatePersonData()}>Actualizar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        {/* Fin Modal editar información general */}


                        {/* Modal agregar contacto para avisar */}

                        <div className='d-flex justify-content-center'>
                            <div class="modal fade" id="addNewContactData" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modalCenter modal-dialog-scrollable">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h2 class="titleText">Agregar un contacto <span className='fieldRequired'>* Requerido</span></h2>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div className='mt-2'>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Nombre <span className='fieldRequired'>*</span></label>
                                                    <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeContactUpdateText("nombre", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Teléfono <span className='fieldRequired'>*</span></label>
                                                    <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeContactUpdateText("telefono", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Teléfono alternativo</label>
                                                    <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeContactUpdateText("telefonoAlternativo", e.target.value)}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary m-2" data-bs-dismiss="modal">Cancelar</button>
                                            <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={() => updateContactPerson()}>Agregar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        {/* Fin Modal agregar contacto para avisar */}

                        {/* Modal editar contacto individual */}

                        <div className='d-flex justify-content-center'>
                            <div class="modal fade" id="editContactModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modalCenter modal-dialog-scrollable">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h2 class="titleText">Editar contacto <span className='fieldRequired'>* Requerido</span></h2>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div className='mt-2'>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Nombre <span className='fieldRequired'>*</span></label>
                                                    <input defaultValue={currentContact.nombre} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeIndividualContactUpdateText("nombre", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Teléfono <span className='fieldRequired'>*</span></label>
                                                    <input defaultValue={currentContact.telefono} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeIndividualContactUpdateText("telefono", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Teléfono alternativo</label>
                                                    <input defaultValue={currentContact.telefonoAlternativo} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeIndividualContactUpdateText("telefonoAlternativo", e.target.value)}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary m-2" data-bs-dismiss="modal" onClick={() => setCurrentContact({})}>Cancelar</button>
                                            <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={() => updateIndividualContact()}>Actualizar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        {/* Fin Modal editar contactos individualmente */}



                        {/* Modal agregar alergia */}

                        <div className='d-flex justify-content-center'>
                            <div class="modal fade" id="addNewAlergiaModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modalCenter modal-dialog-scrollable">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h2 class="titleText">Registrar alergia <span className='fieldRequired'>* Requerido</span></h2>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div className='mt-2'>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Alergia <span className='fieldRequired'>*</span></label>
                                                    <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeAlergiaUpdateText("alergia", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Reacción <span className='fieldRequired'>*</span></label>
                                                    <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeAlergiaUpdateText("reaccion", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Tratamiento</label>
                                                    <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeAlergiaUpdateText("tratamiento", e.target.value)}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary m-2" data-bs-dismiss="modal">Cancelar</button>
                                            <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={() => updateAlergiasListaPerson()}>Agregar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        {/* Fin Modal agregar alergia*/}


                        {/* Modal editar alergia individual */}

                        <div className='d-flex justify-content-center'>
                            <div class="modal fade" id="editAlergiaModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modalCenter modal-dialog-scrollable">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h2 class="titleText">Editar Alergia <span className='fieldRequired'>* Requerido</span></h2>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div className='mt-2'>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Alergia <span className='fieldRequired'>*</span></label>
                                                    <input defaultValue={currentAlergia.alergia} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeIndividualAlergiaUpdateText("alergia", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Reacción <span className='fieldRequired'>*</span></label>
                                                    <input defaultValue={currentAlergia.reaccion} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeIndividualContactUpdateText("reaccion", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Tratamiento</label>
                                                    <input defaultValue={currentAlergia.tratamiento} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeIndividualContactUpdateText("tratamiento", e.target.value)}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary m-2" data-bs-dismiss="modal" onClick={() => setCurrentAlergia({})}>Cancelar</button>
                                            <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={() => updateIndividualAlergia()}>Actualizar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        {/* Fin Modal editar alergias individualmente */}


                        {/* Modal editar información personal */}

                        <div className='d-flex justify-content-center'>
                            <div class="modal fade" id="personalInformationModal" tabindex="-1" aria-hidden="true">
                                <div class="modal-dialog modalCenter modal-dialog-scrollable">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h2 class="titleText">Editar información personal <span className='fieldRequired'>* Requerido</span></h2>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div className='mt-2'>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Fecha de nacimiento <span className='fieldRequired'>*</span></label>
                                                    <input defaultValue={personData.fechaNacimiento} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("fechaNacimiento", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Peso(kg)</label>
                                                    <input defaultValue={personData.peso} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("peso", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Altura(m)</label>
                                                    <input defaultValue={personData.altura} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("altura", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Grupo Sanguíneo</label>
                                                    <input defaultValue={personData.grupoSanguineo} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("grupoSanguineo", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Donante de órganos</label>
                                                    <select class="form-select form-select-lg mb-3" onChange={(e) => handleChangeUpdateText("donanteOrganos", e.target.value)} aria-label=".form-select-lg example">
                                                        <option selected>{personData.donanteOrganos == undefined ? "---" : personData.donanteOrganos} </option>
                                                        <option value="SI">SI</option>
                                                        <option value="NO">NO</option>
                                                    </select>

                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Donante de sangre</label>
                                                    <select class="form-select form-select-lg mb-3" onChange={(e) => handleChangeUpdateText("donanteSangre", e.target.value)} aria-label=".form-select-lg example">
                                                        <option selected>{personData.donanteSangre == undefined ? "---" : personData.donanteSangre} </option>
                                                        <option value="SI">SI</option>
                                                        <option value="NO">NO</option>
                                                    </select>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Número de la Seguridad Social</label>
                                                    <input defaultValue={personData.numeroSeguridadSocial} type="number" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("numeroSeguridadSocial", e.target.value)}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary m-2" data-bs-dismiss="modal">Cancelar</button>
                                            <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={() => updatePersonData()}>Actualizar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        {/* Fin Modal editar información personal */}
                        {/* Modal editar historial clínico */}

                        <div className='d-flex justify-content-center'>
                            <div class="modal fade" id="historialClinicoModal" tabindex="-1" aria-hidden="true">
                                <div class="modal-dialog modalCenter modal-dialog-scrollable">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h2 class="titleText">Editar historial clínico <span className='fieldRequired'>* Requerido</span></h2>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div className='mt-2'>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Religión <span className='fieldRequired'>*</span></label>
                                                    <input defaultValue={personData.religion} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("religion", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Alcohol</label>
                                                    <select class="form-select form-select-lg mb-3" onChange={(e) => handleChangeUpdateText("alcohol", e.target.value)} aria-label=".form-select-lg example">
                                                        <option selected>{personData.alcohol == undefined ? "---" : personData.alcohol} </option>
                                                        <option value="MUY POCO">MUY POCO</option>
                                                        <option value="POCO">POCO</option>
                                                        <option value="REGULAR">REGULAR</option>
                                                        <option value="MUCHO">MUCHO</option>
                                                    </select>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Tabaco</label>
                                                    <select class="form-select form-select-lg mb-3" onChange={(e) => handleChangeUpdateText("tabaco", e.target.value)} aria-label=".form-select-lg example">
                                                        <option selected>{personData.tabaco == undefined ? "---" : personData.tabaco} </option>
                                                        <option value="SI">SI</option>
                                                        <option value="NO">NO</option>
                                                    </select>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Seguro</label>
                                                    <select class="form-select form-select-lg mb-3" onChange={(e) => handleChangeUpdateText("seguro", e.target.value)} aria-label=".form-select-lg example">
                                                        <option selected>{personData.seguro == undefined ? "---" : personData.seguro} </option>
                                                        <option value="SI">SI</option>
                                                        <option value="NO">NO</option>
                                                    </select>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Nombre de compañía</label>
                                                    <input defaultValue={personData.nombreSeguro} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("nombreSeguro", e.target.value)}></input>
                                                </div>

                                                <div className='form-group'>
                                                    <label className='mt-3'>Nº de póliza</label>
                                                    <input defaultValue={personData.numeroPoliza} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("numeroPoliza", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Teléfono de la compañía</label>
                                                    <input defaultValue={personData.telefonoDeSeguro} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("telefonoDeSeguro", e.target.value)}></input>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary m-2" data-bs-dismiss="modal">Cancelar</button>
                                            <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={() => updatePersonData()}>Actualizar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        {/* Fin Modal editar historial clínico */}

                        {/* Modal editar condiciones médicas */}

                        <div className='d-flex justify-content-center'>
                            <div class="modal fade" id="condicionesMedicasModal" tabindex="-1" aria-hidden="true">
                                <div class="modal-dialog modalCenter modal-dialog-scrollable">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h2 class="titleText">Editar condiciones médicas <span className='fieldRequired'>* Requerido</span></h2>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div className='mt-2'>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Enfermedades <span className='fieldRequired'>*</span></label>
                                                    <input defaultValue={personData.enfermedades} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("enfermedades", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Vacunas <span className='fieldRequired'>*</span></label>
                                                    <input defaultValue={personData.vacunas} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("vacunas", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Alergias <span className='fieldRequired'>*</span></label>
                                                    <input defaultValue={personData.alergias} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("alergias", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Problemas de circulación sanguínea</label>
                                                    <input defaultValue={personData.problemasCirculacion} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeUpdateText("problemasCirculacion", e.target.value)}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary m-2" data-bs-dismiss="modal">Cancelar</button>
                                            <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={() => updatePersonData()}>Actualizar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        {/* Fin Modal editar condiciones médicas */}



                        {/* Modal agregar medico */}

                        <div className='d-flex justify-content-center'>
                            <div class="modal fade" id="openNewMedicModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modalCenter modal-dialog-scrollable">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h2 class="titleText">Agregar un médico <span className='fieldRequired'>* Requerido</span></h2>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div className='mt-2'>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Nombre <span className='fieldRequired'>*</span></label>
                                                    <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeMedicState("nombre", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Especialidad <span className='fieldRequired'>*</span></label>
                                                    <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeMedicState("especialidad", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Teléfono</label>
                                                    <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeMedicState("telefonoMedico", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Dirección</label>
                                                    <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeMedicState("direccionMedico", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Ciudad</label>
                                                    <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeMedicState("ciudadMedico", e.target.value)}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary m-2" data-bs-dismiss="modal">Cancelar</button>
                                            <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={() => updateMedicsPerson()}>Agregar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        {/* Fin Modal agregar medico */}

                        {/* Modal editar medico individual */}

                        <div className='d-flex justify-content-center'>
                            <div class="modal fade" id="editIndividualMedic" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modalCenter modal-dialog-scrollable">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h2 class="titleText">Editar médico <span className='fieldRequired'>* Requerido</span></h2>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div className='mt-2'>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Nombre <span className='fieldRequired'>*</span></label>
                                                    <input defaultValue={currentMedic.nombre} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleIndividualMedicUpdateText("nombre", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Especialidad <span className='fieldRequired'>*</span></label>
                                                    <input defaultValue={currentMedic.especialidad} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleIndividualMedicUpdateText("especialidad", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Teléfono</label>
                                                    <input defaultValue={currentMedic.telefonoMedico} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleIndividualMedicUpdateText("telefonoMedico", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Dirección</label>
                                                    <input defaultValue={currentMedic.direccionMedico} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleIndividualMedicUpdateText("direccionMedico", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Ciudad</label>
                                                    <input defaultValue={currentMedic.ciudadMedico} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleIndividualMedicUpdateText("ciudadMedico", e.target.value)}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary m-2" data-bs-dismiss="modal" onClick={() => {
                                                setCurrentMedic({});
                                                setMedicState({});
                                            }}>Cancelar</button>
                                            <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={() => updateIndividualMedicData()}>Actualizar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        {/* Fin Modal editar medico individualmente */}

                        {/* Modal agregar medicamento */}

                        <div className='d-flex justify-content-center'>
                            <div class="modal fade" id="openNewMedicamentoModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modalCenter modal-dialog-scrollable">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h2 class="titleText">Agregar un medicamento <span className='fieldRequired'>* Requerido</span></h2>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div className='mt-2'>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Medicamento <span className='fieldRequired'>*</span></label>
                                                    <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeMedicamentoState("nombre", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Dosis <span className='fieldRequired'>*</span></label>
                                                    <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeMedicamentoState("dosis", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Frecuencia</label>
                                                    <input type="text" class="form-control mb-2 mt-2" onChange={(e) => handleChangeMedicamentoState("frecuencia", e.target.value)}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary m-2" data-bs-dismiss="modal" onClick={() => {
                                                setCurrentMedicamento({});
                                                setMedicamentoState({});
                                            }}>Cancelar</button>
                                            <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={() => updateMedicamentoPerson()}>Agregar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        {/* Fin Modal agregar medicamento */}

                        {/* Modal editar medicamento */}

                        <div className='d-flex justify-content-center'>
                            <div class="modal fade" id="openModalEditIndividualMedicamento" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                <div class="modal-dialog modalCenter modal-dialog-scrollable">
                                    <div class="modal-content">
                                        <div class="modal-header">
                                            <h2 class="titleText">Editar medicamento <span className='fieldRequired'>* Requerido</span></h2>
                                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                        </div>
                                        <div class="modal-body">
                                            <div className='mt-2'>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Medicamento <span className='fieldRequired'>*</span></label>
                                                    <input defaultValue={currentMedicamento.nombre} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleIndividualMedicamentoUpdateText("nombre", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Dosis <span className='fieldRequired'>*</span></label>
                                                    <input defaultValue={currentMedicamento.dosis} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleIndividualMedicamentoUpdateText("dosis", e.target.value)}></input>
                                                </div>
                                                <div className='form-group'>
                                                    <label className='mt-3'>Frecuencia</label>
                                                    <input defaultValue={currentMedicamento.frecuencia} type="text" class="form-control mb-2 mt-2" onChange={(e) => handleIndividualMedicamentoUpdateText("frecuencia", e.target.value)}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="modal-footer">
                                            <button type="button" class="btn btn-secondary m-2" data-bs-dismiss="modal" onClick={() => {
                                                setCurrentMedicamento({});
                                                setMedicamentoState({});
                                            }}>Cancelar</button>
                                            <button className="btn btnMyProfile" data-bs-toggle="modal" data-bs-dismiss="modal" onClick={() => updateIndividualMedicamento()}>Actualizar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>




                        {/* Fin Modal editar medicamento */}


                    </>


            }
        </>
    )
}

export default PersonDetails;