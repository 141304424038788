import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import 'firebase/compat/storage';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const app = firebase.initializeApp({
    apiKey: "AIzaSyBnHRGBEDMzUpREIvI_Vp44mFJTmzLXwqg",
    authDomain: "myqralert.firebaseapp.com",
    projectId: "myqralert",
    storageBucket: "myqralert.appspot.com",
    messagingSenderId: "889982710328",
    appId: "1:889982710328:web:2c9f9405dbe1ac390feccd",
    measurementId: "G-Z6052QV4QJ"
})

export default app;