/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link, useNavigate } from 'react-router-dom';
/**Css styles */
import '../Navigation.css';


/**Transitions */

import app from '../firebase';
const db = app.firestore();






const Login = () => {



    let navigate = useNavigate()
    const [emailUser, setEmailUser] = useState('')
    const [userPassword, setUserPassword] = useState('')

    const handleLogin = async () => {
        if (emailUser != "" && userPassword != "") {
            await app.auth().signInWithEmailAndPassword(emailUser, userPassword).then(async (response) => {

                if (response.user.uid == "DCtk2ejPoUVHgIGjy7r0VbHqTGv1") {
                    await sessionStorage.setItem('adminId', response.user.uid)
                    navigate('/ad/dashboard');

                } else {
                    await localStorage.setItem('userId', response.user.uid);
                    await sessionStorage.setItem('userId', response.user.uid);
                    db.collection("usuarios").where("userId", "==", response.user.uid).get().then((queryString) => {
                        queryString.forEach((doc) => {
                            if (doc.id != "") {
                                navigate('/cl/dashboard');
                            } else {
                                alert("El usuario ha sido eliminado o la cuenta no existe")
                            }
                        })
                    })
                }


            }).catch((error) => {
                if (error.code == "auth/too-many-requests") {
                    alert("Límite de intentos alcanzado, tu cuenta ha sido bloqueada temporalmente, para recuperar el acceso inmediatamente, debes solicitar una nueva, de lo contrario intenta más tarde");
                } else if (error.code == "auth/wrong-password") {
                    alert("Contraseña incorrecta");
                } else {
                    alert("Email incorrecto o no está registrado");
                }
            })
        }
    }

    return (


        <>
            <div className='d-flex flex-column justify-content-center' id='containerLogin'>
                <div className='iconContainerSmall'></div>
                <div className='mt-4 login-card'>
                    <div className='m-4'>
                        <h2 className="firstText">Inicio de sesión</h2>
                        <div className='mt-2'>
                            <div className='form-group'>
                                <label className='mt-3'>Usuario</label>
                                <input type="email" class="form-control mb-2 mt-2" onChange={(e) => setEmailUser(e.target.value)} ></input>
                            </div>
                            <div className='form-group'>
                                <label className='mt-3'>Contraseña</label>
                                <input type="password" class="form-control mb-2 mt-2" onChange={(e) => setUserPassword(e.target.value)}></input>
                            </div>
                        </div>
                    </div>

                    <div className='d-flex flex-row flex-column container-group mb-4'>
                        <button onClick={() => handleLogin()} className="btn btnBlack mb-2">Iniciar sesión</button>
                        <Link to={"/signup"} className="linkView mt-2">¿No tienes cuenta? Crea una aquí</Link>
                        <Link to={"/reset_password"} className="linkView mt-2">¿Olvidaste tu contraseña?</Link>
                        <Link to={"/"} className="linkView mt-2" style={{ color: "#000" }}>Volver al inicio</Link>

                        <div>
                            <span style={{ fontSize: "10px" }}>Al ingresar, estás aceptando nuestros <a href="https://myqralert.com/terminos" target={"_blank"}>términos y condiciones</a></span>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );



}


export default Login;