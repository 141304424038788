/**React imports */
import React, { useState } from 'react';
/**Css styles */
import '../Navigation.css';
import IconInfo from '../components/client-components/IconInfo';

const Info = () => {

    return (


        <div className='bg-yellow-gradient contianer-fluid p-4'>

            {/* Presentación */}
            <div className="p-2">
                <div className='d-flex justify-content-center m-4'>
                    <img src='https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/LOGO-PNG.ico?alt=media&token=0743be12-56ea-4341-957c-f0501675355f' className="pulse pet-circle-image bg-light" alt="INFO | MYQRALERT.COM" />
                </div>
                <div className='text-center'>
                    <span className='fs-2 text-light fw-bold'>MyQrAlert.com</span>
                </div>


                <div className='shadow p-4 my-3 bg-light'>
                    <div className="accordion" id="acordionContacto">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingOne">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <span className='fw-bold fs-4'>Contacto</span>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <div className="container">
                                        <div className="row row-cols-1">
                                            <div className="col-12 col-md-6 d-flex justify-content-center">
                                                <IconInfo iconText={"WhatsApp"} iconLink={"https://api.whatsapp.com/send?phone=+525584627932&text=Hola,%20quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre:"} iconImageLink={"https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/info-icons%2F5528943.webp?alt=media&token=1ac9667d-eb08-45d3-a756-ae772399473c"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='shadow p-4 my-3 bg-light'>
                    <div className="accordion" id="acordionCorreo">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingTwo">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                    <span className='fw-bold fs-4'>Correo</span>
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse show" aria-labelledby="headingTwo" data-bs-parent="#acordionCorreo">
                                <div className="accordion-body">

                                    <div className="container">
                                        <div className="row row-cols-2">
                                            <div className="col-12 col-md-12 d-flex justify-content-center">
                                                <IconInfo iconText={"Mail"} iconLink={"mailto:info@myqralert.com"} iconImageLink={"https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/info-icons%2Femail-message-5309086-4440422.webp?alt=media&token=cd3c5854-f833-4301-a4d2-edb16496dcd1"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='shadow p-4 my-3 bg-light'>
                    <div className="accordion" id="acordionDemo">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingThree">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                    <span className='fw-bold fs-4'>Demo</span>
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse show" aria-labelledby="headingThree" data-bs-parent="#acordionDemo">
                                <div className="accordion-body">
                                    <div className="container">
                                        <div className="row row-cols-2">
                                            <div className="col-12 col-md-12 d-flex justify-content-center">
                                                <IconInfo iconText={"Prueba cómo funciona la plataforma"} iconLink={"https://myqralert.com/tagdemo"} iconImageLink={"https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/LOGO-PNG.ico?alt=media&token=0743be12-56ea-4341-957c-f0501675355f"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='shadow p-4 my-3 bg-light'>
                    <div className="accordion" id="acordionTienda">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFour">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                    <span className='fw-bold fs-4'>Tienda</span>
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse show" aria-labelledby="headingFour" data-bs-parent="#acordionTienda">
                                <div className="accordion-body">
                                    <div className="container">
                                        <div className="row row-cols-2">
                                            <div className="col-12 col-md-12 d-flex justify-content-center">
                                                <IconInfo iconText={"Envíos seguros con MercadoLibre"} iconLink={"https://www.idpetshop.com/qr"} iconImageLink={"https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/info-icons%2Fstore-3980370-3297247.webp?alt=media&token=d277c971-deb5-413c-9938-9f51cd0b10c1"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='shadow p-4 my-3 bg-light'>
                    <div className="accordion" id="acordionWeb">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingFive">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="true" aria-controls="collapseFive">
                                    <span className='fw-bold fs-4'>Web</span>
                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse show" aria-labelledby="headingFive" data-bs-parent="#acordionWeb">
                                <div className="accordion-body">
                                    <div className="container">
                                        <div className="row row-cols-2">
                                            <div className="col-12 col-md-12 d-flex justify-content-center">
                                                <IconInfo iconText={"Ingresa a La Plataforma"} iconLink={"https://myqralert.com"} iconImageLink={"https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/LOGO-PNG.ico?alt=media&token=0743be12-56ea-4341-957c-f0501675355f"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='shadow p-4 my-3 bg-light'>
                    <div className="accordion" id="acordionSoporte">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSeven">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="true" aria-controls="collapseSeven">
                                    <span className='fw-bold fs-4'>Soporte</span>
                                </button>
                            </h2>
                            <div id="collapseSeven" className="accordion-collapse collapse show" aria-labelledby="headingSeven" data-bs-parent="#acordionSoporte">
                                <div className="accordion-body">
                                    <div className="container">
                                        <div className="row row-cols-2">
                                            <div className="col-12 col-md-12 d-flex justify-content-center">
                                                <IconInfo iconText={"¿Problemas en tu cuenta? Escríbenos"} iconLink={"https://api.whatsapp.com/send?phone=+525584627932&text=Hola,%necesito%20ayuda%20con%20mi%20cuenta"} iconImageLink={"https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/info-icons%2Fcall-center-3d-illustration-icon-png.webp?alt=media&token=9b0dd602-a7c6-49a3-ba37-97504b452631"} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className='shadow p-4 my-3 bg-light'>
                    <div className="accordion" id="acordionRedes">
                        <div className="accordion-item">
                            <h2 className="accordion-header" id="headingSix">
                                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="true" aria-controls="collapseSix">
                                    <span className='fw-bold fs-4'>Redes Sociales</span>
                                </button>
                            </h2>
                            <div id="collapseSix" className="accordion-collapse collapse show" aria-labelledby="headingSix" data-bs-parent="#acordionRedes">
                                <div className="accordion-body">
                                    <div className="container">
                                        <div className="row row-cols-3">
                                            <div className="col-12 col-md-4 d-flex justify-content-center">
                                                <IconInfo iconLink={"https://www.facebook.com/myqralert"} iconImageLink={"https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/info-icons%2Ffacebook-5020446-4186922.webp?alt=media&token=bc35a0c4-72bf-4e8b-89b0-f8f6cf2381a1"} />
                                            </div>

                                            <div className="col-12 col-md-4 d-flex justify-content-center">
                                                <IconInfo iconLink={"https://www.instagram.com/myqralert"} iconImageLink={"https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/info-icons%2F4684822.webp?alt=media&token=d3edc823-c50c-4d48-a84a-c9e20abffda2"} />
                                            </div>

                                            <div className="col-12 col-md-4 d-flex justify-content-center">
                                                <IconInfo iconLink={"http://www.tiktok.com/@myqralert"} iconImageLink={"https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/info-icons%2Ftiktok-logo-5075303-4235147.webp?alt=media&token=5f82b76a-3554-4a7d-9da0-fef793969e66"} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='text-center my-2'>
                                        <span className='text-dark fw-bold fs-6'>Síguenos en tu Plataforma favorita</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );



}


export default Info;