import React from 'react'
import { Link } from 'react-router-dom'

export default function Page403() {
  return (
    <div className="container-fluid d-flex justify-content-center align-items-center mh-100">
      <div className="text-center">
        <h2 className='fw-bold'>403 | Access denied</h2>
        <div className="my-2">
        <Link to={'/'} className='btn btn-dark'>Volver al inicio</Link>
        </div>
      </div>
    </div>
  )
}
