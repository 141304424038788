/**React imports */
import React, { useEffect, useState } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../../Navigation.css';

/**Transitions */

import app from '../../../firebase';
const db = app.firestore();


const AlertConfirmSavedPet = () => {

    const [pet, setPet] = useState('');

    const recoverData = () => {

        let pet_obj = localStorage.getItem('@pet_obj');
        pet_obj = JSON.parse(pet_obj);

        setPet(pet_obj);
    }

    useEffect(()=>{
        recoverData();
    },[])
    return (


        <>
            {/* MB */}
            <div className='row' id='row-pc'>

                <div className="col col-3"></div>

                <div className="col col-6">


                    <center>
                        <div style={{ backgroundImage: `url(${pet.petImageLink})` }} className="pet-circle-image m-4"></div>
                    </center>
                    <div className='d-flex flex-column justify-content-center mt-4'>

                        <div className='container'>

                            <div className='row row-cols-1'>
                                <div className='col-md-12'>
                                    <p className="subtitulo">
                                        {pet.petName} se encuentra activo y vigilado en IDPETS.MX
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className='d-flex flex-row flex-column container-group mb-3'>
                         
                            <Link to={'/cl/dashboard'} onClick={()=>localStorage.clear()} style={{ width: "300px", background: "#28C2E3", color: "#fff" }} className="btn btn-main-rose mt-4">Ir al inicio</Link>
                        </div>

                    </div>
                </div>

                <div className="col col-3"></div>
            </div>

            {/* MB */}
            <div className='row' id='row-mb'>

                <div className="col col-3"></div>

                <div className="col col-6">


                    <center>
                        <div style={{ backgroundImage: `url(${pet.petImageLink})` }} className="pet-circle-image m-4"></div>
                    </center>
                    <div className='d-flex flex-column justify-content-center mt-4'>

                        <div className='container'>

                            <div className='row row-cols-1'>
                                <div className='col-md-12'>
                                    <p className="subtitulo">
                                        {pet.petName} se encuentra activo y vigilado en IDPETS.MX
                                    </p>
                                </div>
                            </div>

                        </div>

                        <div className='d-flex flex-row flex-column container-group mb-3'>
                         
                            <Link to={'/cl/dashboard'} onClick={()=>localStorage.clear()} style={{ width: "300px", background: "#28C2E3", color: "#fff" }} className="btn btn-main-rose mt-4">Ir al inicio</Link>
                        </div>

                    </div>
                </div>

                <div className="col col-3"></div>
            </div>
        </>
    );



}


export default AlertConfirmSavedPet;