/**React imports */
import React, { Component, useEffect, Fragment, useState } from 'react';
/**Router */
import { Link } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';


/**Transitions */

import app from '../../firebase';
const db = app.firestore();


const Tag = (props) => {

    return (
        <>
            <Link to={`/cl/person_details/${props.tagId}`} className='btn tagContainer'>
                <div style={{backgroundImage:`url(${props.imgUrl})`,backgroundSize:"cover",backgroundPosition:"center !important"}} className="tagImage"></div>
                <label className='tagPersonName'>{props.nombre}</label>
            </Link>
        </>
    );
}


export default Tag;