import React from 'react'
import { useNavigate, useParams, Link } from 'react-router-dom';
export default function ConfirmTagDemo() {

    let params = useParams();

    return (
        <div className="shadow w-50 mw-auto p-4 bg-light m-4">
            <div className="d-flex justify-content-center align-items-center">
                <div className="iconContainer"></div>
            </div>
            <div className="form-group my-2 text-center">
                <span className='fw-bold text-dark fs-2'>¡Listo, este es tu TagDemo!</span>
                <div className="my-4 text-center">
                    <Link target={"_blank"} to={`/tagDemoDetails/${params.tagId}`} className='btn btn-full-rounded bg-orange fw-bold text-light fs-2'>Ver tag ➤</Link>
                </div>
                <div className="my-4 text-center">
                    <Link to="/" className="text-dark fs-6 text-decoration-underline">Volver a MYQRALERT</Link>
                </div>
            </div>
        </div>
    )
}
