/**React imports */
import React, { useEffect, useState } from 'react';
/**Router */
import { useParams } from 'react-router-dom';
/**Css styles */
import '../../Navigation.css';
import emailjs from 'emailjs-com'

import app from '../../firebase';
//importing icons
import whatsAppIcon from '../../assets/icons/whatsAppIcon.svg';

const db = app.firestore();


const TagDemo = () => {

    let params = useParams();

    const [personData, setPersonData] = useState('');

    const [isLoading, setIsLoading] = useState(true);


    const timenow = () => {
        var now = new Date(),
            ampm = 'am',
            h = now.getHours(),
            m = now.getMinutes(),
            s = now.getSeconds();
        if (h >= 12) {
            if (h > 12) h -= 12;
            ampm = 'pm';
        }

        if (m < 10) m = '0' + m;
        if (s < 10) s = '0' + s;
        return now.toLocaleDateString() + ' ' + h + ':' + m + ':' + s + ' ' + ampm;
    }

    useEffect(() => {

        db.collection('tagsDemo').where("tagId", "==", params.personId).onSnapshot((querySnapshot) => {
            querySnapshot.docs.forEach((doc) => {
                setPersonData(doc.data())
                setIsLoading(false)
                navigator.geolocation.getCurrentPosition((pos) => {

                    db.collection("mail")
                        .add({
                            to: doc.data().correoEmergencia,
                            message: {
                                subject: "SE HA LOCALIZADO A" + doc.data().nombrePortador,
                                text: "LECTURA DE QR",
                                html: `
                            <p>Se ha registrado la localización de: ${doc.data().nombrePortador}</p>
                            <p>&nbsp;</p>
                            <p style="padding-left: 40px;">Ubicación: https://www.google.com/maps/place/${pos.coords.latitude},${pos.coords.longitude}</p>
                            <p style="padding-left: 40px;">Fecha de lectura de QR: ${timenow()}</p>
                            <p style="padding-left: 40px;">tagId:${params.personId}</p>
                            <p style="padding-left: 40px;">&nbsp;</p>
                            <center>
                            <p><img src="https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/LOGO-PNG.ico?alt=media&token=0743be12-56ea-4341-957c-f0501675355f" alt="" width="150" height="150"></p>
                            </center>
                            `,
                            },
                        }).then(() => console.log("::ALERT SENT SUCCESSFULLY::"));
                })
            })
        })


    }, []);

    return (

        <>

            {
                isLoading == true ?
                    <>
                        <div className='containerDashboard'>
                            <div class="spinner-border text-dark" role="status">
                                <span class="visually-hidden">Cargando información</span>
                            </div>
                        </div>
                    </>

                    :

                    <div className="containerDashboard">

                        {/* Foto */}

                        <h2 className='userNameText' id="row-pc">Detalles de {personData.nombrePortador}</h2>

                        <div className="containerPersonPhoto" id="row-pc">
                            <div style={{ backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/card-imgs%2Favatar-icon.png?alt=media&token=d813157c-15fb-4a6f-afd1-a62bd09d61b6})`, borderRadius: "50%", width: "200px", height: "200px" }} className="pet-circle-image-details shadow my-2"></div>
                        </div>


                        <div className="shadow-table my-4" id='row-mb'>
                            <center>
                                <div style={{ backgroundImage: `url(https://firebasestorage.googleapis.com/v0/b/myqralert.appspot.com/o/card-imgs%2Favatar-icon.png?alt=media&token=d813157c-15fb-4a6f-afd1-a62bd09d61b6)`, borderRadius: "50%", width: "200px", height: "200px" }} className="pet-circle-image-details shadow my-2"></div>

                                <div className="containerNameMb">
                                    <span className='nameUserCard'>
                                        {personData.nombrePortador}
                                    </span>
                                </div>

                            </center>
                        </div>

                        {/* Información vital */}
                        {
                            personData.informacionVital !== undefined ?
                                <div className="container-vital-info p-2">
                                    <span className='display-6 text-dark fw-bold'>{personData.informacionVital}</span>
                                </div>
                                :
                                <div></div>
                        }
                        {/* Fin información vital */}

                        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-2">

                            <div className="col my-4">
                                <div class="accordion-item containerButtonAcordion">
                                    <h2 class="accordion-header" id="headingOne">
                                        <button class="buttonCardAcordion accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            Ver datos generales
                                        </button>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">

                                            <div className='m-2'>
                                                <span className='titleText'>Nombre: </span><span className='dataText'>{personData.nombrePortador}</span>
                                            </div>
                                            <div className='m-2'>
                                                <span className='titleText'>Dirección: </span><span className='dataText'>{personData.direccionPortador}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col my-4">
                                <div class="accordion-item containerButtonAcordion">
                                    <h2 class="accordion-header" id="headingTwo">
                                        <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            Avisar a
                                        </button>
                                    </h2>
                                    <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">

                                            <div className="row row-cols-2">
                                                <div className="col">
                                                    <div className='m-2'>
                                                        <span className='titleText'>Nombre: </span><span className='dataText'>{personData.responsable}</span>
                                                    </div>
                                                    <div className='m-2'>
                                                        <span className='titleText text-green'>Telefono: </span><a target={"_blank"} href={`tel:${personData.telefonoEmergencia}`} className='dataText text-success'>{personData.telefonoEmergencia}</a>
                                                    </div>
                                                </div>
                                                <div className="col">
                                                    <a target={"_blank"} href={`https://api.whatsapp.com/send?phone=+52${personData.telefonoEmergencia}`}>
                                                        <img src={whatsAppIcon} className="whatsAppIcon" alt="" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col my-4">
                                <div class="accordion-item containerButtonAcordion">
                                    <h2 class="accordion-header" id="headingThree">
                                        <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                            Datos personales
                                        </button>
                                    </h2>
                                    <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            <div className='m-2'>
                                                <span className='titleText'>Grupo Sangúineo: </span><span className='dataText'>{personData.tipoSangre}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col my-4">
                                <div class="accordion-item containerButtonAcordion">
                                    <h2 class="accordion-header" id="headingFive">
                                        <button class="buttonCardAcordion accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                            Condiciones médicas
                                        </button>
                                    </h2>
                                    <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">

                                            <div className='m-2'>
                                                <span className='titleText'>Enfermedades: </span><span className='dataText'>{personData.enfermedades}</span>
                                            </div>
                                            <div className='m-2'>
                                                <span className='titleText'>Alergias </span><span className='dataText'>{personData.alergias}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div className="my-4">
                            <span className='text-dark fw-bold fs-5'>Desbloquea más funciones importantes y cuida de los tuyos.  <a href="https://idpetshop.com" className='text-orange'>Adquiere tu <strong>Placa inteligente aquí</strong></a> </span>
                        </div>
                    </div>
            }
        </>
    );
}
export default TagDemo;